import React from 'react';
import { Button, Text, useColorModeValue } from '@chakra-ui/react';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export const NewAccountFooter = () => {
  const textColor = useColorModeValue('white', 'black');
  const buttonColor = useColorModeValue('gray', 'blackAlpha');

  const navigate = useNavigate();

  const handleNewAccountClick = () => {
    navigate('/register');
  };

  return (
    <>
      <Text color={textColor} fontSize="xl" fontWeight="bold">
        Need to create an account?
      </Text>
      <Button
        onClick={handleNewAccountClick}
        colorScheme={buttonColor}
        size="lg"
        leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
        marginTop={2}
      >
        Start my free account
      </Button>
    </>
  );
};
