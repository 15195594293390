import { z } from 'zod';

export const PRODUCTS = 'products';
export const BRANDS = 'brands';
export const CUSTOMERS = 'customers';
export const ORDERS = 'orders';
export const VARIANTS = 'variants';
export const CART = 'cart';

export const ProductEntrySchema = z.object({
  ProductId: z.string(),
  Category: z.string(),
  Variables: z.array(z.string()),
  Brand: z.string(),
  Logo: z.string(),
  Description: z.string(),
  Brochure: z.string(),
});
// PK: ProductId
// SK: ProductId
export type ProductEntry = z.infer<typeof ProductEntrySchema>;

export const BrandEntrySchema = z.object({
  Brand: z.string(),
  Logo: z.string(),
  Description: z.string(),
  WebSite: z.string(),
});
// PK: Brand
// SK: Brand
export type BrandEntry = z.infer<typeof BrandEntrySchema>;

export const CustomerEntrySchema = z.object({
  CustomerId: z.string(),
  UserName: z.string(),
  Email: z.string(),
  Addresses: z.record(z.string(), z.string()),
  PaymentMethods: z.record(z.string(), z.string()),
});
// PK: CustomerId
// SK: CustomerId
export type CustomerEntry = z.infer<typeof CustomerEntrySchema>;

export const ProductVariantEntrySchema = z.object({
  ProductId: z.string(),
  VariantId: z.string(),
  SKU: z.string(),
  Combination: z.record(z.string(), z.string()),
  Description: z.string(),
  Image: z.string(),
  Price: z.string(),
  Available: z.number(),
});
// PK: ProductId
// SK: VariantId
export type ProductVariantEntry = z.infer<typeof ProductVariantEntrySchema>;

export const CustomerOrderEntrySchema = z.object({
  CustomerId: z.string(),
  OrderId: z.string(),
  UserName: z.string(),
  Email: z.string(),
  PaymentMethod: z.string(),
  Address: z.string(),
  StatusDate: z.string(),
});

// PK: CustomerId
// SK: OrderId
export type CustomerOrderEntry = z.infer<typeof CustomerOrderEntrySchema>;

export const OrderLineItemEntrySchema = z.object({
  ProductId: z.string(),
  OrderId: z.string(),
  VariantId: z.string(),
  SKU: z.string(),
  Combination: z.record(z.string(), z.string()),
  Description: z.string(),
  Image: z.string(),
  Price: z.string(),
  Quantity: z.number(),
});
// PK: OrderId
// SK: VariantId
export type OrderLineItemEntry = z.infer<typeof OrderLineItemEntrySchema>;

export const CartLineItemEntrySchema = z.object({
  ProductId: z.string(),
  VariantId: z.string(),
  SKU: z.string(),
  Combination: z.record(z.string(), z.string()),
  Description: z.string(),
  Image: z.string(),
  Price: z.string(),
  Quantity: z.number(),
});
// PK: CustomerId
// SK: VariantId
export type CartLineItemEntry = z.infer<typeof CartLineItemEntrySchema>;

// GSI 1
// PK: CustomerId
// SK: StatusDate
export const CustomerStatusDateEntrySchema = CustomerOrderEntrySchema;
export type CustomerStatusDateEntry = z.infer<typeof CustomerStatusDateEntrySchema>;

// GSI2
// PK: Category
// SK: ProductId
export const CategoryProductEntrySchema = ProductEntrySchema;
export type CategoryProductEntry = z.infer<typeof CategoryProductEntrySchema>;

// GSI3
// PK: Brand
// SK: ProductId
export const BrandProductEntrySchema = ProductEntrySchema;
export type BrandProductEntry = z.infer<typeof BrandProductEntrySchema>;

const CartCheckoutSchema = z.object({
  UserName: z.string(),
  Email: z.string(),
  Address: z.string(),
  PaymentMethod: z.string(),
});
export type CartCheckout = z.infer<typeof CartCheckoutSchema>;

/**
 * ----------------------------------------------------------------------------
 * Warehouse Types
 * ----------------------------------------------------------------------------
 */

export const WHITEMS = 'whitems';
// PK: VariantId
// SK: WHLocId

export const WarehouseEntrySchema = z.object({
  VariantId: z.string(),
  WHLocId: z.string(),
  SKU: z.string(),
  BinLocId: z.string(),
  Quantity: z.number(),
});
export type WarehouseEntry = z.infer<typeof WarehouseEntrySchema>;
