import { ColorModeScript, createStandaloneToast } from '@chakra-ui/react';
import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { theme } from './theme';

// Font imports
import '@fontsource/quicksand';
import '@fontsource/raleway';
import '@fontsource/ubuntu-mono';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// https://chakra-ui.com/getting-started/migration#2-update-createstandalonetoast
const { ToastContainer } = createStandaloneToast();

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ToastContainer />
    <App />
  </React.StrictMode>
);
