import React, { useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Image,
  Link as ChakraLink,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useDeleteProductMutation } from 'api/Admin';
import { useProductsByCategoryQuery } from 'api/Customer';
import { ProductEntry, ProductEntrySchema } from 'types';
import { Link } from 'react-router-dom';
import { ConfirmationModal } from 'components/ConfirmationModal';

const TABLE_COLUMNS = Object.keys(ProductEntrySchema.shape);

export const ProductsTable = () => {
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const [deleteItem, setDeleteItem] = useState('');

  const toast = useToast();
  const { mutate, status } = useDeleteProductMutation();

  const handleDelete = (productId: string) => {
    mutate(productId, {
      onSuccess: () => {
        toast({
          title: 'Deleted product',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Unable to delete product',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  // TODO: single products list
  // Temporary workaround for lack of a full product list API
  const { data: accessoriesData = [] } = useProductsByCategoryQuery('Accessories');
  const { data: childrensClothingData = [] } =
    useProductsByCategoryQuery(`Children's Clothing`);

  const { data: mensClothingData = [] } = useProductsByCategoryQuery(`Men's Clothing`);

  const { data: womensClothingData = [] } =
    useProductsByCategoryQuery(`Women's Clothing`);

  const { data: footwearData = [] } = useProductsByCategoryQuery('Footwear');

  const products = accessoriesData.concat(
    childrensClothingData,
    mensClothingData,
    womensClothingData,
    footwearData
  );

  return (
    <>
      <Flex justifyContent="space-between" marginBottom={3}>
        <Heading>Products</Heading>

        <Link to="new">
          <Button>Add new product</Button>
        </Link>
      </Flex>

      <TableContainer overflowY="scroll">
        <Table variant="simple">
          <Thead>
            <Tr>
              {TABLE_COLUMNS.map((col) => (
                <Th key={col}>{col}</Th>
              ))}

              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {products.map((product) => (
              <Tr key={product.ProductId}>
                {TABLE_COLUMNS.map((col) => {
                  switch (col) {
                    case 'Variables':
                      return <Td key={col}>{product.Variables.join(',')}</Td>;
                    case 'Logo':
                      return (
                        <Td key={col} backgroundColor="white">
                          <Image
                            src={`${process.env.REACT_APP_IMAGE_URL}/${product.Logo}`}
                            alt={product.Description}
                            width="60px"
                            height="60px"
                          />
                        </Td>
                      );
                    case 'Brochure':
                      return (
                        <Td key={col}>
                          <ChakraLink
                            href={`${process.env.REACT_APP_IMAGE_URL}/${product.Brochure}`}
                            isExternal={true}
                            color="chakrablue.300"
                          >
                            {product.Brochure}
                          </ChakraLink>
                        </Td>
                      );
                    default:
                      return <Td key={col}>{product[col as keyof ProductEntry]}</Td>;
                  }
                })}

                <Td>
                  <Link to={product.ProductId}>
                    <Button size="xs">Edit</Button>
                  </Link>
                  <Button
                    size="xs"
                    colorScheme="red"
                    onClick={() => {
                      setDeleteItem(product.ProductId);
                      onDeleteModalOpen();
                    }}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete product"
        actionDescription={
          <>
            Are you sure you want to delete <Tag colorScheme="teal">{deleteItem}</Tag> ?
          </>
        }
        onClose={onDeleteModalClose}
        actionButtonText="Delete"
        handleAction={() => handleDelete(deleteItem)}
        actionButtonColor="red"
        status={status}
      />
    </>
  );
};
