import React, { ReactNode, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  ModalProps,
} from '@chakra-ui/react';

type ConfirmationModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  title: string;
  actionDescription: ReactNode;
  handleAction: () => void;
  status?: 'error' | 'idle' | 'loading' | 'success';
  actionButtonText?: string;
  actionButtonColor?: string;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    title,
    actionDescription,
    isOpen,
    onClose,
    handleAction,
    status,
    actionButtonText,
    actionButtonColor,
  } = props;

  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (status === 'success') onClose();
  }, [status, onClose]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody>{actionDescription}</AlertDialogBody>

          <AlertDialogFooter mb={2}>
            <ButtonGroup>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={actionButtonColor ?? 'blue'}
                onClick={() => handleAction()}
                isLoading={status === 'loading'}
              >
                {actionButtonText}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
