import React, { useState } from 'react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  Button,
  PopoverArrow,
  ButtonGroup,
  useDisclosure,
  Portal,
  Editable,
  EditablePreview,
  Input,
  useToast,
} from '@chakra-ui/react';
import { WarehouseEntry } from 'types';
import {
  useUpdateBinLocIdMutation,
  useUpdateQuantityMutation,
} from 'api/Admin/warehouse';

type PopoverEditButtonProps = {
  warehouseEntry: WarehouseEntry;
  isQuantity: boolean;
};

export const PopoverEditButton = (props: PopoverEditButtonProps) => {
  const toast = useToast();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { warehouseEntry, isQuantity } = props;


  const [inputValue, setInputValue] = useState('');

  const { mutate: updateQuantity } = useUpdateQuantityMutation();
  const { mutate: updateBinLocId } = useUpdateBinLocIdMutation();

  const handleModify = () => {
    const { VariantId, WHLocId } = warehouseEntry;
    if (isQuantity) {
      updateQuantity(
        { VariantId, WHLocId, Quantity: Number(inputValue) },
        {
          onSuccess: () => {
            toast({
              title: 'Updated quantity',
              status: 'success',
              isClosable: true,
            });
            onClose();
            setInputValue('');
          },
          onError: () => {
            toast({
              title: 'Unable to update quantity',
              status: 'error',
              isClosable: true,
            });
          },
        }
      );
    } else {
      updateBinLocId(
        { VariantId, WHLocId, BinLocId: inputValue },
        {
          onSuccess: () => {
            toast({
              title: 'Updated bin location',
              status: 'success',
              isClosable: true,
            });
            onClose();
            setInputValue('');
          },
          onError: () => {
            toast({
              title: 'Unable to update bin location',
              status: 'error',
              isClosable: true,
            });
          },
        }
      );
    }
  };

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <PopoverTrigger>
        <Button size="sm" onClick={() => onToggle()}>
          {isQuantity ? warehouseEntry.Quantity : warehouseEntry.BinLocId}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Edit value</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            New {isQuantity ? 'quantity' : 'bin location'}:
            <Editable>
              <EditablePreview />
              <Input
                onChange={(e) => setInputValue(e.target.value)}
                defaultValue={
                  isQuantity ? warehouseEntry.Quantity : warehouseEntry.BinLocId
                }
              />
            </Editable>
          </PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button colorScheme="red" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="blue" onClick={() => handleModify()}>
                Modify
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
