import React from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationBar, NavigationBarLink } from 'components/NavigationBar';
import { PageLayout } from 'components/PageLayout';

const ADMIN_LINKS: NavigationBarLink[] = [
  { to: 'products', text: 'Products' },
  { to: 'warehouse', text: 'Warehouse' },
];

export const AdminPage = () => {
  return (
    <PageLayout headerContent={<NavigationBar links={ADMIN_LINKS} />}>
      <Outlet />
    </PageLayout>
  );
};
