import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { adminOnError, getRequestHeaders } from 'api/util';
import axios, { AxiosRequestHeaders } from 'axios';
import { useUserContext } from 'context/useUserContext';
import { useNavigate } from 'react-router-dom';
import { storeJWTs } from 'services/TokenService';

import { WarehouseEntry, WarehouseEntrySchema, WHITEMS } from 'types';
import { getEcommerceUrl } from 'util/url';
import { z } from 'zod';

const axiosInstance = axios.create({
  baseURL: getEcommerceUrl(),
  method: 'post',
  withCredentials: true,
});

// Cast as AxiosRequestHeaders (temporary workaround)
// https://github.com/axios/axios/issues/5034
axiosInstance.interceptors.request.use(
  (config) => {
    return { ...config, headers: getRequestHeaders('protected') as AxiosRequestHeaders };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    storeJWTs({
      idToken: response.headers.id_token,
      accessToken: response.headers.access_token,
    });
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Adding New Warehouse Item

const addWHItem = async (warehouseEntry: WarehouseEntry): Promise<void> => {
  const response = await axiosInstance.post('/warehouse', warehouseEntry, {
    params: { act: 'add' },
  });
  return response.data;
};

export const useAddWHItemMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  return useMutation((warehouseEntry: WarehouseEntry) => addWHItem(warehouseEntry), {
    onMutate: async (warehouseEntry) => {
      queryClient.cancelQueries([WHITEMS]);
      const prevEntries = queryClient.getQueryData([WHITEMS]);
      queryClient.setQueryData<WarehouseEntry[]>([WHITEMS], (prev) => [
        ...(prev ?? []),
        warehouseEntry,
      ]);
      return { prevEntries };
    },
    onError: (e, __, context) => {
      queryClient.setQueryData([WHITEMS], context?.prevEntries);
      adminOnError(setIsAdmin, e, navigate);
    },
    onSettled: () => {
      queryClient.invalidateQueries([WHITEMS]);
    },
  });
};

// Updating Quantity
const WHItemQtyUpdatePayloadSchema = WarehouseEntrySchema.pick({
  VariantId: true,
  WHLocId: true,
  Quantity: true,
});
type WHItemQtyUpdatePayload = z.infer<typeof WHItemQtyUpdatePayloadSchema>;

const updateQuantity = async (warehouseEntry: WHItemQtyUpdatePayload): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/warehouse',
    data: warehouseEntry,
    params: { act: 'update-qty' },
  });
  return response.data;
};

export const useUpdateQuantityMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  return useMutation(
    (warehouseEntry: WHItemQtyUpdatePayload) => updateQuantity(warehouseEntry),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WHITEMS]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

// Updating Bin Location
const WHItemBinLocIdUpdatePayloadSchema = WarehouseEntrySchema.pick({
  VariantId: true,
  WHLocId: true,
  BinLocId: true,
});
type WHItemBinLocIdUpdatePayload = z.infer<typeof WHItemBinLocIdUpdatePayloadSchema>;

const updateBinLocId = async (
  warehouseEntry: WHItemBinLocIdUpdatePayload
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/warehouse',
    data: warehouseEntry,
    params: { act: 'update-bin' },
  });
  return response.data;
};

export const useUpdateBinLocIdMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  return useMutation(
    (warehouseEntry: WHItemBinLocIdUpdatePayload) => updateBinLocId(warehouseEntry),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WHITEMS]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

// Delete Warehouse Item
const deleteWHItem = async (VariantId: string, WHLocId: string): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/warehouse',
    data: { VariantId, WHLocId },
    params: { act: 'delete' },
  });
  return response.data;
};

export const useDeleteWHItemMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  return useMutation(
    (data: { variantId: string; whLocId: string }) =>
      deleteWHItem(data.variantId, data.whLocId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WHITEMS]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

// List all Warehouse Items
const fetchWHItemDetail = async (): Promise<WarehouseEntry[]> => {
  const response = await axiosInstance.request({
    url: '/warehouse',
    data: { WHItem: 'True' },
    params: { act: 'list' },
  });
  return z.array(WarehouseEntrySchema).parse(response.data);
};

export const useWHItemDetailQuery = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();

  return useQuery({
    queryKey: [WHITEMS],
    queryFn: () => fetchWHItemDetail(),
    onError: (e) => adminOnError(setIsAdmin, e, navigate),
  });
};
