import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useUserContext } from 'context/useUserContext';

import {
  useCartCheckoutMutation,
  useCustomerDetailQuery,
  useDeleteProductVariantFromCartMutation,
  useEmptyCartMutation,
  useProductVariantsInCartQuery,
} from 'api/Customer';
import { CartCheckout, CustomerEntry, VARIANTS } from 'types';

const emptyCustomerEntry: CustomerEntry = {
  CustomerId: '',
  UserName: '',
  Email: '',
  Addresses: {},
  PaymentMethods: {},
};

export const ShoppingCartView = () => {
  const { isAuthed } = useUserContext();
  const { data: customerEntry = emptyCustomerEntry } = useCustomerDetailQuery();

  // Takes a bit of time for the backend to process the checkout
  const [recentlyCheckedOut, setRecentlyCheckedOut] = useState(false);
  useEffect(() => {
    setRecentlyCheckedOut(false);
  }, []);

  const toast = useToast();

  const { data: variants } = useProductVariantsInCartQuery();
  const isEmptyCart = recentlyCheckedOut || (variants ?? []).length === 0;

  const { mutate: checkoutMutation } = useCartCheckoutMutation();
  const queryClient = useQueryClient();

  const checkout = () => {
    const paymentNames = Object.keys(customerEntry.PaymentMethods);
    const addressNames = Object.keys(customerEntry.Addresses);
    if (paymentNames.length < 1) {
      toast({
        title: 'No payment method',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (addressNames.length < 1) {
      toast({
        title: 'No address',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const cartCheckout: CartCheckout = {
      UserName: customerEntry.UserName,
      Email: customerEntry.Email,
      Address: addressNames[0],
      PaymentMethod: paymentNames[0],
    };

    checkoutMutation(cartCheckout, {
      onSuccess: () => {
        setRecentlyCheckedOut(true);
        // Variant quantities will update after checkout
        variants?.forEach((variant) => {
          queryClient.invalidateQueries([VARIANTS, { productId: variant.ProductId }]);
        });
        toast({
          title: 'Checked out',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Error during checkout',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  const { mutate: emptyCartMutate } = useEmptyCartMutation();
  const emptyCart = () => {
    emptyCartMutate(undefined, {
      onSuccess: () => {
        toast({
          title: 'Emptied cart',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Error emptying cart',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  const { mutate: deleteVariantMutation } = useDeleteProductVariantFromCartMutation();
  const deleteVariantFromCart = (variantId: string) => {
    deleteVariantMutation(variantId, {
      onSuccess: () => {
        toast({
          title: 'Removed from cart',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Error removing item',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <Flex flexDirection="column" height="88vh">
      <ButtonGroup marginBottom={4}>
        <Button colorScheme="green" onClick={checkout} isDisabled={isEmptyCart}>
          Checkout
        </Button>
        <Button onClick={emptyCart} isDisabled={isEmptyCart}>
          Empty cart
        </Button>
      </ButtonGroup>

      {isEmptyCart || !isAuthed ? (
        <Text>Cart is empty</Text>
      ) : (
        <Stack spacing={3} overflowY="auto">
          {variants?.map(
            ({
              VariantId,
              Description,
              Image: VariantImage,
              Price,
              Combination,
              Quantity,
            }) => (
              <Card key={VariantId} size="sm">
                <CardHeader>
                  <Heading size="md">{Description}</Heading>
                </CardHeader>

                <CardBody>
                  <Flex>
                    <Image
                      src={`${process.env.REACT_APP_IMAGE_URL}/${VariantImage}`}
                      alt={Description}
                      width="110px"
                      height="110px"
                    />

                    <Flex flexGrow={1}>
                      <TableContainer>
                        <Table variant="simple" size="sm">
                          <Tbody>
                            <Tr>
                              <Td>Price:</Td>
                              <Td>
                                <Text color="chakrablue.300">${Price}</Text>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Variant:</Td>
                              <Td>
                                {Object.entries(Combination).map(([variable, value]) => (
                                  <Text key={variable}>
                                    {variable}: {value}
                                  </Text>
                                ))}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Quantity:</Td>
                              <Td>{Quantity}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>

                    <Button
                      colorScheme="red"
                      onClick={() => deleteVariantFromCart(VariantId)}
                    >
                      Remove
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            )
          )}
        </Stack>
      )}
    </Flex>
  );
};
