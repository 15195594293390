import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  LightMode,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserContext } from 'context/useUserContext';

import { AccountPageLayout } from 'components/AccountPageLayout';
import { AccountPageInput } from 'components/AccountPageInput';
import { LoginRequestData } from 'api/Identity';
import { getErrorMessage } from 'util/error';

import { NewAccountFooter } from './NewAccountFooter';

type LocationState =
  | {
      from?: {
        pathname: string;
      };
      username?: string;
    }
  | undefined;

const LoginPage = () => {
  const { signIn } = useUserContext();
  /** REACT-ROUTER STATE */
  const { state }: { state: LocationState } = useLocation();
  const navigate = useNavigate();

  // VerifyEmailPage will pass this in the location
  const username = state?.username;

  const toast = useToast();

  useEffect(() => {
    // username is defined when redirected from VerifyEmailPage (successful verification)
    if (username) {
      toast({
        title: 'Verification success',
        description: 'Your email has been verified; you can now log into Coreograph.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [username, toast]);

  /** API STATE */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequestData>({
    defaultValues: {
      username,
      password: '',
    },
  });

  const onSubmit = async (data: LoginRequestData) => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      const { isAdmin } = await signIn(data);

      setIsLoading(false);

      // Send user back to the page before being redirected to the login page if it exists
      // else redirect to admin or customer page depending on userType
      navigate(state?.from?.pathname ?? (isAdmin ? '/admin' : '/customer'), {
        replace: true,
        state: { isAdmin },
      });
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setErrorMessage('Invalid username or password.');
      } else {
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  return (
    <AccountPageLayout
      title="Log in"
      errorMessage={errorMessage}
      footerContent={<NewAccountFooter />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <LightMode>
          <Stack spacing={3} color="black">
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="email">Email address</FormLabel>
              <AccountPageInput
                id="email"
                placeholder="Email address"
                type="email"
                autoFocus={true}
                {...register('username', {
                  required: 'Please enter your email address',
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <AccountPageInput
                id="password"
                placeholder="Password"
                type="password"
                {...register('password', {
                  required: 'Please enter your password',
                })}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <ButtonGroup spacing={6} marginTop={6}>
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              leftIcon={<FontAwesomeIcon icon={faUnlock} />}
              isLoading={isLoading}
            >
              Login
            </Button>
            <Button variant="link" onClick={() => navigate('/reset-password')}>
              Forgot my password
            </Button>
          </ButtonGroup>
        </LightMode>
      </form>
    </AccountPageLayout>
  );
};

export default LoginPage;
