import React from 'react';

import {
  Alert,
  AlertIcon,
  Stack,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Text,
  Button,
} from '@chakra-ui/react';
import { NavigateFunction } from 'react-router-dom';

type ForbiddenToastProps = {
  type: 'admin' | 'customer';
  onClose: () => void;
  isClosable?: boolean;
  navigate: NavigateFunction;
};

export const ForbiddenToast = (props: ForbiddenToastProps) => {
  const { type, onClose, isClosable, navigate } = props;

  const handleNavigate = () => {
    navigate('/login');
    onClose();
  };

  return (
    <Alert
      status="warning"
      variant="solid"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      width="auto"
    >
      <AlertIcon />
      <Stack spacing="2px">
        <AlertTitle>{type === 'admin' ? 'Unauthorized' : 'Not logged in'}</AlertTitle>
        <AlertDescription display="block">
          <Button variant="link" onClick={handleNavigate}>
            <Text as="u" color="chakrablue.600">
              {type === 'admin' ? 'Change account' : 'Login'}
            </Text>
          </Button>
        </AlertDescription>
      </Stack>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={onClose}
          position="absolute"
          insetEnd={1}
          top={1}
        />
      )}
    </Alert>
  );
};
