import React from 'react';
import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useVariantsOfProductQuery } from 'api/Customer';

import { ProductPageLayout } from '../ProductPageLayout';
import { VariantForm } from './VariantForm';

export const Variant = () => {
  const { productId, variantId } = useParams() as {
    productId: string;
    variantId: string;
  };

  const { data: variants, isError, isLoading } = useVariantsOfProductQuery(productId);
  const variant = variants?.find((v) => v.VariantId === variantId);

  if (isLoading) {
    return <Skeleton height="1000px" />;
  }

  if (isError || variant === undefined) {
    return <Text>Error</Text>;
  }

  return (
    <ProductPageLayout
      title={`Variant: ${variantId}`}
      hasBackButton={true}
      backNavButtonProps={{
        to: `../${productId}`,
        text: 'Back to product',
      }}
    >
      <Flex justifyContent="center" padding={10} paddingTop={0}>
        <VariantForm variant={variant} />
      </Flex>
    </ProductPageLayout>
  );
};
