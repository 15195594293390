import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Flex,
  Button,
  List,
  ListItem,
  useToast,
  Link,
} from '@chakra-ui/react';
import { useAddProductVariantToCartMutation, useBrandDetailQuery } from 'api/Customer';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import { ProductEntry, ProductVariantEntry } from 'types';

type ProductModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  productEntry: ProductEntry;
  productVariants?: ProductVariantEntry[];
};

const placeholderVariant: ProductVariantEntry = {
  ProductId: 'P0001',
  Description: 'Decription placeholder',
  VariantId: 'V0001',
  SKU: 'SKU0001',
  Combination: { Size: 'one size', Color: 'black' },
  Image: 'https://via.placeholder.com/500',
  Price: '149.99',
  Available: 984,
};

export const ProductModal = (props: ProductModalProps) => {
  const { isOpen, onClose, productEntry, productVariants } = props;

  const [variantIndex, setVariantIndex] = useState(0);
  const [userQuantity, setUserQuantity] = useState(1);

  const toast = useToast();

  const variant =
    productVariants && productVariants[variantIndex]
      ? productVariants[variantIndex]
      : placeholderVariant;

  const variantImage = useMemo(() => {
    if (variant && variant.Image && variant !== placeholderVariant)
      return `${process.env.REACT_APP_IMAGE_URL}/${variant.Image}`;
    return 'https://via.placeholder.com/500';
  }, [variant]);

  const brochurePath = `${process.env.REACT_APP_IMAGE_URL}/${productEntry.Brochure}`;

  const { data: brandDetail } = useBrandDetailQuery(productEntry.Brand);

  const { mutate, isLoading } = useAddProductVariantToCartMutation();

  const handleAddtoCart = () => {
    if (!variant || !userQuantity) return;
    const { ProductId, VariantId } = variant;
    mutate(
      { ProductId, VariantId, Quantity: userQuantity },
      {
        onSuccess: () => {
          toast({
            title: 'Added item to cart',
            status: 'success',
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: 'Unable to add item to cart',
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>{productEntry.Description}</Heading>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack gap={3} justifyContent="center" display="flex">
            <Grid
              height="50%"
              width="100%"
              maxH="80vh"
              templateColumns="repeat(3,1fr)"
              templateRows="repeat(1,1fr)"
              gap={5}
            >
              <GridItem rowSpan={1} colSpan={1}>
                <Image
                  src={variantImage}
                  alt={variant.Description}
                  borderRadius="lg"
                  maxW="33vw"
                  maxH="80vh"
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={2}>
                <Stack gap={3} height="100%">
                  <Box borderRadius="lg" borderWidth="2px" height="100%">
                    <Table variant="simple" height="80%">
                      <Thead>
                        <Tr>
                          <Th>Product Details</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Description:</Td>
                          <Td>
                            <Text>{variant.Description}</Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Category:</Td>
                          <Td>{productEntry.Category}</Td>
                        </Tr>
                        <Tr>
                          <Td>Price:</Td>
                          <Td>
                            <Text color="blue.600" fontSize="2xl">
                              ${variant.Price}
                            </Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Variations: </Td>
                          <Td>
                            <RadioGroup
                              onChange={(value) => setVariantIndex(Number(value))}
                              value={String(variantIndex)}
                            >
                              <Grid
                                templateColumns="repeat(3,1fr)"
                                templateRows="auto"
                                gap={2}
                              >
                                {productVariants?.map((v, k) => (
                                  <GridItem key={v.VariantId}>
                                    <Radio value={String(k)}>
                                      <List>
                                        {Object.entries(v.Combination).map(
                                          ([combinationKey, combinationValue]) => {
                                            return (
                                              <ListItem key={combinationKey}>
                                                {combinationKey}: {combinationValue}
                                              </ListItem>
                                            );
                                          }
                                        )}
                                      </List>
                                    </Radio>
                                  </GridItem>
                                ))}
                              </Grid>
                            </RadioGroup>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Quantity:</Td>
                          <Td>
                            <Flex alignItems="center" gap={2}>
                              <NumberInput
                                defaultValue={userQuantity}
                                max={variant.Available}
                                min={1}
                                onChange={(e) => setUserQuantity(Number(e))}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                              {variant.Available} pieces available
                            </Flex>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                    <Flex justifyContent="center" gap={20} m={5}>
                      <Button
                        isLoading={isLoading}
                        isDisabled={userQuantity < 1 || userQuantity > variant.Available}
                        onClick={() => handleAddtoCart()}
                        colorScheme="blue"
                      >
                        Add to cart
                      </Button>
                    </Flex>
                  </Box>
                </Stack>
              </GridItem>
            </Grid>
            <Box borderRadius="lg" borderWidth="2px">
              <Table variant="simple" size="md">
                <Thead>
                  <Tr>
                    <Th>Brand Details</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      {brandDetail?.Logo && (
                        <Image
                          src={`${process.env.REACT_APP_IMAGE_URL}/${brandDetail?.Logo}`}
                          alt={brandDetail.Description}
                          width="100px"
                          height="100px"
                          borderRadius="lg"
                          background="white"
                        />
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Brand:</Td>
                    <Td>{brandDetail?.Brand}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description: </Td>
                    <Td>{brandDetail?.Description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Website: </Td>
                    <Td>
                      <Link
                        href={brandDetail?.WebSite}
                        isExternal={true}
                        color="chakrablue.300"
                      >
                        {brandDetail?.WebSite}
                      </Link>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            <Document file={brochurePath}>
              {Array(5)
                .fill(1)
                .map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Page key={index} pageNumber={index + 1} />
                ))}
            </Document>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
