import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { NewProductVariantEntrySchema, useAddProductVariantMutation } from 'api/Admin';
import { ProductPageLayout } from './ProductPageLayout';

const NewVariantFormSchema = NewProductVariantEntrySchema.extend({
  Combination: z.array(z.array(z.string(), z.string())),
});
type NewVariantForm = z.infer<typeof NewVariantFormSchema>;

export const NewVariant = () => {
  const { productId } = useParams() as { productId: string };
  const navigate = useNavigate();

  const defaultValues: NewVariantForm = {
    ProductId: productId,
    SKU: '',
    Combination: [[]],
    Description: '',
    Image: '',
    Price: '',
    Available: 0,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<NewVariantForm>({
    defaultValues,
    resolver: zodResolver(NewVariantFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Combination',
  });

  const { mutate, isLoading } = useAddProductVariantMutation();

  const toast = useToast();

  const onSubmit = (data: NewVariantForm) => {
    const newVariant = {
      ...data,
      Combination: Object.fromEntries(data.Combination),
    };

    mutate(newVariant, {
      onSuccess: () => {
        toast({
          title: 'Variant added',
          status: 'success',
          isClosable: true,
        });

        navigate(`../${productId}`);
      },
      onError: () => {
        toast({
          title: 'Variant creation failed',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <ProductPageLayout
      title="New variant"
      hasBackButton={true}
      backNavButtonProps={{
        to: `../${productId}`,
        text: 'Back to product',
      }}
    >
      <Flex justifyContent="center" padding={10} paddingTop={0}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {Object.keys(NewVariantFormSchema.shape).map((field) => {
            switch (field) {
              case 'SKU':
              case 'Image':
              case 'Price':
              case 'Available':
                return (
                  <FormControl key={field} isInvalid={!!errors[field]}>
                    <FormLabel htmlFor={field}>{field}</FormLabel>
                    <Input
                      id={field}
                      {...register(field, {
                        ...(field === 'Available' && { valueAsNumber: true }),
                      })}
                      {...(field === 'Available' && { type: 'number' })}
                    />
                    <FormErrorMessage>{errors[field]?.message}</FormErrorMessage>
                  </FormControl>
                );
              case 'Combination':
                return (
                  <FormControl key={field} isInvalid={!!errors[field]}>
                    <FormLabel htmlFor={field}>{field}</FormLabel>
                    <Stack>
                      <List spacing={2}>
                        {fields.map((item, index) => {
                          return (
                            <ListItem key={item.id}>
                              <HStack spacing={2}>
                                <Input
                                  {...register(`${field}.${index}.${0}`, {
                                    required: true,
                                  })}
                                />
                                <Input
                                  {...register(`${field}.${index}.${1}`, {
                                    required: true,
                                  })}
                                />
                                <Button
                                  colorScheme="red"
                                  size="sm"
                                  onClick={() => remove(index)}
                                >
                                  X
                                </Button>
                              </HStack>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Button
                        onClick={() => append([[]])}
                        width="100%"
                        colorScheme="blue"
                      >
                        +
                      </Button>
                    </Stack>
                    <FormErrorMessage>
                      {errors[field]?.message as string | undefined}
                    </FormErrorMessage>
                  </FormControl>
                );
              case 'Description':
                return (
                  <FormControl key={field} isInvalid={!!errors[field]}>
                    <FormLabel htmlFor={field}>{field}</FormLabel>
                    <Textarea id={field} {...register(field)} />
                    <FormErrorMessage>
                      {errors[field]?.message as string | undefined}
                    </FormErrorMessage>
                  </FormControl>
                );
              default:
                return null;
            }
          })}

          <ButtonGroup marginTop={4}>
            <Button type="submit" colorScheme="blue" isDisabled={!isDirty || isLoading}>
              Save
            </Button>
          </ButtonGroup>
        </form>
      </Flex>
    </ProductPageLayout>
  );
};
