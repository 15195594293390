import React, { ReactNode } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { Header } from 'components/Header';

type PageLayoutType = {
  children: ReactNode;
  headerContent?: ReactNode;
};

export const PageLayout = (props: PageLayoutType) => {
  const { children, headerContent } = props;

  const backgroundColor = useColorModeValue('lightblue', 'whiteAlpha.200');

  return (
    <Flex height="100vh" direction="column" backgroundColor={backgroundColor}>
      <Header>{headerContent}</Header>

      <Flex
        as="main"
        flex={1}
        flexDirection="column"
        overflow="hidden"
        paddingLeft={6}
        paddingRight={6}
      >
        {children}
      </Flex>
    </Flex>
  );
};
