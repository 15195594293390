export const parseStatusDate = (
  statusDate: string
): { status: string; date: Date } | undefined => {
  const split = statusDate.split('#');
  if (split.length !== 2) return undefined;
  return {
    status: split[0],
    date: new Date(Number(split[1]) * 1000),
  };
};

export const capitalize = (word: string) => {
  return `${word[0].toUpperCase()}${word.slice(1)}`;
};
