import React from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Image,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useLineItemsInOrderQuery } from 'api/Customer';
import { OrderLineItemEntry } from 'types';

type OrderLineItemsProps = {
  orderId: string;
};

const lineItemPlaceholder: OrderLineItemEntry = {
  ProductId: 'productId',
  OrderId: 'orderId',
  VariantId: 'variantId',
  SKU: 'SKU',
  Combination: { some: 'thing' },
  Description: 'description',
  Image: 'https://via.placeholder.com/150',
  Price: '100',
  Quantity: 3,
};

export const OrderLineItems = (props: OrderLineItemsProps) => {
  const { orderId } = props;

  const { data = Array<OrderLineItemEntry>(3).fill(lineItemPlaceholder) } =
    useLineItemsInOrderQuery(orderId);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Order Items</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(
            ({
              VariantId,
              Description,
              Image: VariantImage,
              Price,
              Combination,
              Quantity,
            }) => (
              <Tr>
                <Td>
                  <Card key={VariantId} size="sm">
                    <CardHeader>
                      <Heading size="md">{Description}</Heading>
                    </CardHeader>

                    <CardBody>
                      <Flex>
                        <Image
                          src={`${process.env.REACT_APP_IMAGE_URL}/${VariantImage}`}
                          alt={Description}
                          width="110px"
                          height="110px"
                        />

                        <Flex flexGrow={1}>
                          <TableContainer>
                            <Table variant="simple" size="sm">
                              <Tbody>
                                <Tr>
                                  <Td>Price:</Td>
                                  <Td>
                                    <Text color="chakrablue.300">${Price}</Text>
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td>Variant:</Td>
                                  <Td>
                                    {Object.entries(Combination).map(
                                      ([variable, value]) => (
                                        <Text key={variable}>
                                          {variable}: {value}
                                        </Text>
                                      )
                                    )}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td>Quantity:</Td>
                                  <Td>{Quantity}</Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
