import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import {
  useAddAddressMutation,
  useAddPaymentMethodMutation,
  useCustomerDetailQuery,
  useDeleteAddressMutation,
  useDeletePaymentMethodMutation,
} from 'api/Customer';

type ProductModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>;

export const ProfileModal = (props: ProductModalProps) => {
  const { isOpen, onClose } = props;

  const [loadingKey, setLoadingKey] = useState<string>();
  const { isFetching, data: customerDetails } = useCustomerDetailQuery();

  useEffect(() => {
    if (!isFetching) setLoadingKey(undefined);
  }, [isFetching]);

  const [addressNameInput, setAddressNameInput] = useState('');
  const [addressInput, setAddressInput] = useState('');

  const addAddress = useAddAddressMutation();
  const handleAddAddress = () => {
    if (!addressInput || !addressNameInput || !customerDetails) return;
    addAddress.mutate({
      addressName: addressNameInput,
      address: addressInput,
    });
    setLoadingKey(addressNameInput);
    // clear inputs
    setAddressInput('');
    setAddressNameInput('');
  };

  const deleteAddress = useDeleteAddressMutation();
  const handleDeleteAddress = (addressName: string) => {
    if (!customerDetails) return;
    deleteAddress.mutate(addressName);
    setLoadingKey(addressName);
    // clear inputs
    setAddressInput('');
    setAddressNameInput('');
  };

  const [paymentNameInput, setPaymentNameInput] = useState('');
  const [paymentInput, setPaymentInput] = useState('');

  const addPayment = useAddPaymentMethodMutation();
  const handleAddPayment = () => {
    if (!paymentNameInput || !paymentInput || !customerDetails) return;
    addPayment.mutate({
      paymentName: paymentNameInput,
      payment: paymentInput,
    });
    setLoadingKey(paymentNameInput);
    // clear inputs
    setPaymentInput('');
    setPaymentNameInput('');
  };

  const deletePayment = useDeletePaymentMethodMutation();
  const handleDeletePayment = (paymentName: string) => {
    if (!customerDetails) return;
    deletePayment.mutate(paymentName);
    setLoadingKey(paymentName);
    // clear inputs
    setPaymentInput('');
    setPaymentNameInput('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Heading>My Profile</Heading>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack gap={2}>
            <Box borderRadius="lg" borderWidth="2px" display="flex">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <Heading size="md">Basic Info</Heading>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Customer identifier</Td>
                    <Td>{customerDetails?.CustomerId}</Td>
                  </Tr>
                  <Tr>
                    <Td>Username</Td>
                    <Td>{customerDetails?.UserName} </Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{customerDetails?.Email}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box borderRadius="lg" borderWidth="2px" display="flex">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <Heading size="md">Addresses</Heading>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(customerDetails?.Addresses ?? {}).map(([k, v]) => {
                    return (
                      <Tr key={k} overflow="auto">
                        <Td>{k}</Td>
                        <Td maxW="50vw">{v}</Td>
                        <Td>
                          <Button
                            isLoading={k === loadingKey}
                            onClick={() => handleDeleteAddress(k)}
                            colorScheme="red"
                          >
                            Delete
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td>
                      <Input
                        value={addressNameInput}
                        variant="filled"
                        placeholder="Name"
                        onChange={(e) => {
                          setAddressNameInput(e.target.value);
                        }}
                      />
                    </Td>
                    <Td>
                      <Input
                        value={addressInput}
                        variant="filled"
                        placeholder="Address"
                        onChange={(e) => {
                          setAddressInput(e.target.value);
                        }}
                      />
                    </Td>
                    <Td>
                      <Button
                        isLoading={addressNameInput === loadingKey}
                        isDisabled={!addressInput || !addressNameInput}
                        onClick={() => handleAddAddress()}
                        colorScheme="blue"
                      >
                        Add
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box borderRadius="lg" borderWidth="2px" display="flex">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <Heading size="md">Payment Info</Heading>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(customerDetails?.PaymentMethods ?? {}).map(([k, v]) => {
                    return (
                      <Tr key={k} overflow="auto">
                        <Td>{k}</Td>
                        <Td maxW="50vw">{v}</Td>
                        <Td>
                          <Button
                            isLoading={k === loadingKey}
                            onClick={() => handleDeletePayment(k)}
                            colorScheme="red"
                          >
                            Delete
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td>
                      <Input
                        value={paymentNameInput}
                        variant="filled"
                        placeholder="Payment name"
                        onChange={(e) => {
                          setPaymentNameInput(e.target.value);
                        }}
                      />
                    </Td>
                    <Td>
                      <Input
                        value={paymentInput}
                        variant="filled"
                        placeholder="Payment info"
                        onChange={(e) => {
                          setPaymentInput(e.target.value);
                        }}
                      />
                    </Td>
                    <Td>
                      <Button
                        isLoading={paymentNameInput === loadingKey}
                        isDisabled={!paymentInput || !paymentNameInput}
                        onClick={() => handleAddPayment()}
                        colorScheme="blue"
                      >
                        Add
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
