import React, { useState } from 'react';
import { Flex, Heading, Button } from '@chakra-ui/react';

import { WarehouseTable } from './components/WarehouseTable';
import { NewWarehouseItemModal } from './NewWarehouseItemModal';

export const WarehouseView = () => {
  const [isNewWarehouseItemModalOpen, setIsNewWarehouseItemModalOpen] = useState(false);

  return (
    <Flex flexDirection="column" height="90vh">
      <Flex justifyContent="space-between" marginBottom={3}>
        <Heading>Warehouse Items</Heading>

        <Button onClick={() => setIsNewWarehouseItemModalOpen(true)}>Add new item</Button>
      </Flex>
      <WarehouseTable />
      <NewWarehouseItemModal
        isOpen={isNewWarehouseItemModalOpen}
        onClose={() => setIsNewWarehouseItemModalOpen(false)}
      />
    </Flex>
  );
};
