import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  Button,
  Avatar,
  MenuList,
  MenuGroup,
  MenuItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { faRightFromBracket, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserContext } from 'context/useUserContext';

import { ProfileModal } from './ProfileModal';

export const ProfileMenu = () => {
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const { isAuthed, logOut } = useUserContext();

  const navigate = useNavigate();

  if (!isAuthed)
    return (
      <Button colorScheme="blue" onClick={() => navigate('/login')}>
        Log in
      </Button>
    );

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} background="transparent">
          <Avatar size="sm" />
        </MenuButton>
        <MenuList>
          <MenuGroup>
            <MenuItem
              icon={<FontAwesomeIcon icon={faUser} />}
              onClick={() => setProfileModalOpen(true)}
            >
              My profile
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon color="tomato" icon={faRightFromBracket} />}
              onClick={() => logOut()}
            >
              Logout
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={() => setProfileModalOpen(false)}
      />
    </>
  );
};
