import React from 'react';
import { Flex, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { NavLink, To } from 'react-router-dom';

export type NavigationBarLink = {
  to: To;
  text: string;
};

type NavigationBarProps = {
  links: NavigationBarLink[];
};

export const NavigationBar = (props: NavigationBarProps) => {
  const { links } = props;

  return (
    <Flex justifyContent="space-between" alignItems="center" gap={2}>
      <Breadcrumb separator="-">
        {links.map(({ to, text }) => (
          <BreadcrumbItem key={text}>
            <BreadcrumbLink
              as={NavLink}
              to={to}
              p={3}
              borderRadius="lg"
              _activeLink={{ background: 'whiteAlpha.200' }}
            >
              {text}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  );
};
