import React, { useState } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { VirtuosoGrid } from 'react-virtuoso';
import { useProductsByBrandQuery, useProductsByCategoryQuery } from 'api/Customer';

import { ProductEntry } from 'types';
import './index.css';
import { ProductCard } from './ProductCard';

const sampleProduct: ProductEntry = {
  ProductId: 'P0001',
  Category: 'Accessories',
  Variables: ['Size', 'Color'],
  Brand: 'Michael Kors',
  Logo: 'brandlogo/mk.png',
  Description: "Michael Kors Women's Jet Set Travel Tote",
  Brochure: 'brochure/mk.pdf',
};

const SORT_TITLES = ['All', 'Brands', 'Categories'] as const;
type SortTitlesType = (typeof SORT_TITLES)[number];

export const HomeView = () => {
  const brands = [
    'Michael Kors',
    'Ralph Lauren',
    'Adidas',
    'Calvin Klein',
    "Levi's",
    'Nike',
    'Patagonia',
  ];
  const categories = [
    'Accessories',
    "Children's Clothing",
    'Footwear',
    "Men's Clothing",
    "Women's Clothing",
  ];
  const [sortBy, setSortBy] = useState(brands[0]);
  const [sortTitle, setSortTitle] = useState<SortTitlesType>('All');
  const [sortedByBrands, setSortedByBrands] = useState(true);

  // should rewrite this into one useQuery (conditional boolean)
  const { data: productsByBrands } = useProductsByBrandQuery(sortBy);
  const { data: productsByCategories } = useProductsByCategoryQuery(sortBy);
  const products =
    (sortedByBrands ? productsByBrands : productsByCategories) ??
    Array<ProductEntry>(1).fill(sampleProduct);

  // TODO: single products list
  // Temporary workaround for lack of a full product list API
  const { data: accessoriesData = [] } = useProductsByCategoryQuery(categories[0]);
  const { data: childrensClothingData = [] } = useProductsByCategoryQuery(categories[1]);
  const { data: mensClothingData = [] } = useProductsByCategoryQuery(categories[2]);
  const { data: womensClothingData = [] } = useProductsByCategoryQuery(categories[3]);
  const { data: footwearData = [] } = useProductsByCategoryQuery(categories[4]);

  const allProducts = accessoriesData.concat(
    childrensClothingData,
    mensClothingData,
    womensClothingData,
    footwearData
  );

  return (
    <>
      <Flex justifyContent="start" alignItems="center" gap={2} mb={2}>
        <Text>Filter by</Text>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {sortTitle === 'All' ? 'All' : sortBy}
          </MenuButton>
          <MenuList>
            <MenuGroup title="All">
              <MenuItemOption onClick={() => setSortTitle('All')}>All</MenuItemOption>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Brands">
              {brands.map((name) => {
                return (
                  <MenuItemOption
                    key={name}
                    value={name}
                    onClick={(e) => {
                      setSortBy(e.currentTarget.value);
                      setSortedByBrands(true);
                      setSortTitle('Brands');
                    }}
                  >
                    {name}
                  </MenuItemOption>
                );
              })}
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Categories">
              {categories.map((name) => {
                return (
                  <MenuItemOption
                    key={name}
                    value={name}
                    onClick={(e) => {
                      setSortBy(e.currentTarget.value);
                      setSortedByBrands(false);
                      setSortTitle('Categories');
                    }}
                  >
                    {name}
                  </MenuItemOption>
                );
              })}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>

      <VirtuosoGrid
        itemClassName="itemClassName"
        listClassName="listClassName"
        data={sortTitle === 'All' ? allProducts : products}
        // eslint-disable-next-line react/no-unstable-nested-components
        itemContent={(index) => {
          return (
            <ProductCard
              key={index}
              productEntry={sortTitle === 'All' ? allProducts[index] : products[index]}
            />
          );
        }}
      />
    </>
  );
};
