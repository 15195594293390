import React from 'react';

import { Input } from '@chakra-ui/react';
import { Column } from '@tanstack/react-table';

export const FilterInput = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <Input
      type="text"
      variant="unstyled"
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.currentTarget.value)}
      placeholder={`Search... `}
    />
  );
};
