import { createStandaloneToast } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { getJWTAuthBearer } from 'services/TokenService';
import { isForbiddenError } from 'util/error';
import { isLocalhost } from 'util/url';
import { ForbiddenToast } from 'components/Toast/ForbiddenToast';

type RequestType = 'unprotected' | 'protected';

const { toast } = createStandaloneToast();

const FORBIDDEN_TOAST_ID = 'forbidden-toast';

export const getRequestHeaders = (type: RequestType) => {
  if (type === 'unprotected') {
    return {
      'Content-Type': 'text/plain; charset=UTF-8',
      // Add null Authorization header to send pre-flight request on localhost
      // https://coreograph.slack.com/archives/C02CUKV428P/p1660659618295049?thread_ts=1660647037.012479&cid=C02CUKV428P
      ...(isLocalhost() && { Authorization: null }),
    };
  }
  if (type === 'protected') {
    return {
      'Content-Type': 'text/plain; charset=UTF-8',
      Authorization: getJWTAuthBearer(),
    };
  }
};

export const customerOnError = (
  setIsAuthed: Dispatch<SetStateAction<boolean>>,
  error: unknown,
  navigate?: NavigateFunction
) => {
  if (isForbiddenError(error) && navigate) {
    if (!toast.isActive(FORBIDDEN_TOAST_ID)) {
      toast({
        id: FORBIDDEN_TOAST_ID,
        isClosable: true,
        duration: null,
        render: ({ isClosable, onClose }) => (
          <ForbiddenToast
            type="customer"
            isClosable={isClosable}
            onClose={onClose}
            navigate={navigate}
          />
        ),
      });
    }
  }
  if (isForbiddenError(error)) setIsAuthed(false);
};

export const adminOnError = (
  setIsAdmin: Dispatch<SetStateAction<boolean>>,
  error: unknown,
  navigate?: NavigateFunction
) => {
  if (isForbiddenError(error) && navigate) {
    if (!toast.isActive(FORBIDDEN_TOAST_ID)) {
      toast({
        id: FORBIDDEN_TOAST_ID,
        isClosable: true,
        duration: null,
        render: ({ isClosable, onClose }) => (
          <ForbiddenToast
            type="admin"
            isClosable={isClosable}
            onClose={onClose}
            navigate={navigate}
          />
        ),
      });
    }
  }
  if (isForbiddenError(error)) setIsAdmin(false);
};
