import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  ButtonGroup,
  Button,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUpdateProductMutation } from 'api/Admin';
import { ProductEntry, ProductEntrySchema } from 'types';

const ProductFormSchema = ProductEntrySchema.extend({
  Variables: z.string(),
});
type ProductForm = z.infer<typeof ProductFormSchema>;

type ProductDetailsFormProps = {
  product: ProductEntry;
};

export const ProductDetailsForm = (props: ProductDetailsFormProps) => {
  const { product } = props;

  const defaultValues: ProductForm = {
    ...product,
    // Convert list to string (comma-delimited list)
    Variables: product.Variables.join(','),
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ProductForm>({
    defaultValues,
    resolver: zodResolver(ProductFormSchema),
  });

  const productMutation = useUpdateProductMutation();
  const { mutate, isLoading } = productMutation;

  const toast = useToast();

  const onSubmit = (data: ProductForm) => {
    const updatedProduct: ProductEntry = {
      ...data,
      Variables: data.Variables.split(','),
    };

    mutate(updatedProduct, {
      onSuccess: () => {
        toast({
          title: 'Product updated',
          status: 'success',
          isClosable: true,
        });
        reset(data);
      },
      onError: () => {
        toast({
          title: 'Product update failed',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.ProductId}>
        <FormLabel htmlFor="productId">ProductId</FormLabel>
        <Input disabled={true} id="productId" {...register('ProductId')} />
        <FormErrorMessage>{errors.ProductId?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Category}>
        <FormLabel htmlFor="category">Category</FormLabel>
        <Input id="category" {...register('Category')} />
        <FormErrorMessage>{errors.Category?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Brand}>
        <FormLabel htmlFor="brand">Brand</FormLabel>
        <Input id="brand" {...register('Brand')} />
        <FormErrorMessage>{errors.Brand?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Description}>
        <FormLabel htmlFor="description">Description</FormLabel>
        <Textarea id="description" {...register('Description')} />
        <FormErrorMessage>{errors.Description?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Logo}>
        <FormLabel htmlFor="logo">Logo (path to file)</FormLabel>
        <Input id="logo" {...register('Logo')} />
        <FormErrorMessage>{errors.Logo?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Brochure}>
        <FormLabel htmlFor="brochure">Brochure (path to file)</FormLabel>
        <Input id="brochure" {...register('Brochure')} />
        <FormErrorMessage>{errors.Brochure?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.Variables}>
        <FormLabel htmlFor="variables">Variables (comma-delimited list)</FormLabel>
        <Input id="variables" {...register('Variables')} />
        <FormErrorMessage>{errors.Variables?.message}</FormErrorMessage>
      </FormControl>

      <ButtonGroup marginTop={4}>
        <Button type="submit" colorScheme="blue" isDisabled={!isDirty || isLoading}>
          Save
        </Button>
      </ButtonGroup>
    </form>
  );
};
