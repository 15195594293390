export const isLocalhost = () => {
  return (
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  );
};

export const getIdentityUrl = () => {
  return process.env.REACT_APP_IDENTITY_URL;
};

export const getEcommerceUrl = () => {
  return process.env.REACT_APP_ECOMMERCE_URL;
};

export const getWarehouseUrl = () => {
  return process.env.REACT_APP_WAREHOUSE_URL;
};
