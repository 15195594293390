import React, { ReactNode } from 'react';
import { Flex, Heading } from '@chakra-ui/react';

import { BackNavButton, BackNavButtonProps } from 'components/BackNavButton';

type ProductPageProps = {
  title: string;
  hasBackButton?: boolean;
  backNavButtonProps?: BackNavButtonProps;
  children: ReactNode;
};

export const ProductPageLayout = (props: ProductPageProps) => {
  const { title, hasBackButton = false, backNavButtonProps, children } = props;

  const { to = '..', text = 'Back to products' } = backNavButtonProps ?? {};

  return (
    <Flex flexDirection="column" overflow="hidden">
      <Flex alignItems="center" marginBottom={4}>
        {hasBackButton && <BackNavButton to={to} text={text} marginRight={4} />}
        <Heading>{title}</Heading>
      </Flex>

      <Flex flexDirection="column" overflowY="auto">
        {children}
      </Flex>
    </Flex>
  );
};
