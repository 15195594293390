import React from 'react';
import {
  Button,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useVariantsOfProductQuery } from 'api/Customer';
import { useDeleteProductVariantMutation } from 'api/Admin';

import { ProductVariantEntrySchema } from 'types';
import { Link } from 'react-router-dom';

const TABLE_COLUMNS = Object.keys(ProductVariantEntrySchema.shape);

type VariantsTabProps = {
  productId: string;
};

export const ProductVariantsTab = (props: VariantsTabProps) => {
  const { productId } = props;

  const toast = useToast();

  const { data: variants } = useVariantsOfProductQuery(productId);

  const { mutate } = useDeleteProductVariantMutation();

  const handleDeleteVariant = (variantId: string) => {
    mutate(
      { productId, variantId },
      {
        onSuccess: () => {
          toast({
            title: 'Deleted variant',
            status: 'success',
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: 'Unable to delete variant',
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {TABLE_COLUMNS.map((col) => (
                <Th key={col}>{col}</Th>
              ))}

              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {variants?.map((variant) => {
              const {
                VariantId,
                ProductId,
                SKU,
                Combination,
                Description,
                Price,
                Available,
              } = variant;

              return (
                <Tr key={VariantId}>
                  <Td>{ProductId}</Td>
                  <Td>{VariantId}</Td>
                  <Td>{SKU}</Td>
                  <Td>
                    {Object.entries(Combination).map(([variable, value]) => (
                      <Text key={variable}>
                        {variable}: {value}
                      </Text>
                    ))}
                  </Td>
                  <Td>{Description}</Td>
                  <Td>
                    <Image
                      src={`${process.env.REACT_APP_IMAGE_URL}/${variant.Image}`}
                      alt={Description}
                      width="80px"
                      height="80px"
                    />
                  </Td>
                  <Td>{Price}</Td>
                  <Td>{Available}</Td>

                  <Td>
                    <Link to={`variants/${VariantId}`}>
                      <Button size="xs">Edit</Button>
                    </Link>
                    <Button
                      size="xs"
                      colorScheme="red"
                      onClick={() => handleDeleteVariant(VariantId)}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Link to="variants/new">
        <Button>New variant</Button>
      </Link>
    </>
  );
};
