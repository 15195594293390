import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adminOnError, getRequestHeaders } from 'api/util';
import axios, { AxiosRequestHeaders } from 'axios';
import { useUserContext } from 'context/useUserContext';
import { useNavigate } from 'react-router-dom';
import { storeJWTs } from 'services/TokenService';

import {
  ORDERS,
  ProductEntry,
  ProductEntrySchema,
  PRODUCTS,
  ProductVariantEntry,
  ProductVariantEntrySchema,
  VARIANTS,
} from 'types';
import { getEcommerceUrl } from 'util/url';
import { z } from 'zod';

const axiosInstance = axios.create({
  baseURL: getEcommerceUrl(),
  method: 'post',
  withCredentials: true,
});

// Cast as AxiosRequestHeaders (temporary workaround)
// https://github.com/axios/axios/issues/5034
axiosInstance.interceptors.request.use(
  (config) => {
    return { ...config, headers: getRequestHeaders('protected') as AxiosRequestHeaders };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    storeJWTs({
      idToken: response.headers.id_token,
      accessToken: response.headers.access_token,
    });
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * ----------------------------------------------------------------------------
 * Product management
 * ----------------------------------------------------------------------------
 */

const NewProductEntrySchema = ProductEntrySchema.omit({ ProductId: true });
type NewProductEntry = z.infer<typeof NewProductEntrySchema>;

const addProduct = async (productEntry: NewProductEntry): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop',
    data: productEntry,
    params: { act: 'add' },
  });
  return response.data;
};

export const useAddProductMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation((productEntry: NewProductEntry) => addProduct(productEntry), {
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCTS]);
    },
    onError: (e) => adminOnError(setIsAdmin, e, navigate),
  });
};

const updateProduct = async (productEntry: ProductEntry): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop',
    data: productEntry,
    params: { act: 'update' },
  });
  return response.data;
};

export const useUpdateProductMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation((productEntry: ProductEntry) => updateProduct(productEntry), {
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCTS]);
    },
    onError: (e) => adminOnError(setIsAdmin, e, navigate),
  });
};

// returns empty string
const deleteProduct = async (productId: string): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop',
    data: { ProductId: productId },
    params: { act: 'delete' },
  });
  return response.data;
};

export const useDeleteProductMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation((productId: string) => deleteProduct(productId), {
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCTS]);
    },
    onError: (e) => adminOnError(setIsAdmin, e, navigate),
  });
};

export const NewProductVariantEntrySchema = ProductVariantEntrySchema.omit({
  VariantId: true,
});
export type NewProductVariantEntry = z.infer<typeof NewProductVariantEntrySchema>;

const addProductVariant = async (
  productVariantEntry: NewProductVariantEntry
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop/variants',
    data: productVariantEntry,
    params: { act: 'add' },
  });
  return response.data;
};

export const useAddProductVariantMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (productVariantEntry: NewProductVariantEntry) =>
      addProductVariant(productVariantEntry),
    {
      onSuccess: (_, { ProductId }) => {
        queryClient.invalidateQueries([VARIANTS, { productId: ProductId }]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

const updateProductVariant = async (
  productVariantEntry: ProductVariantEntry
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop/variants',
    data: productVariantEntry,
    params: { act: 'update' },
  });
  return response.data;
};

export const useUpdateProductVariantMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (productVariantEntry: ProductVariantEntry) =>
      updateProductVariant(productVariantEntry),
    {
      onSuccess: (_, { ProductId }) => {
        queryClient.invalidateQueries([VARIANTS, { productId: ProductId }]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

// Backend returns empty string
const deleteProductVariant = async (
  productId: string,
  variantId: string
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop/variants',
    data: { ProductId: productId, VariantId: variantId },
    params: { act: 'delete' },
  });
  return response.data;
  // return z.void().parse(response.data);
};

export const useDeleteProductVariantMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { productId: string; variantId: string }) =>
      deleteProductVariant(data.productId, data.variantId),
    {
      onSuccess: (_, { productId }) => {
        queryClient.invalidateQueries([VARIANTS, { productId }]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

const adjustVariantInventory = async (
  productId: string,
  variantId: string,
  delta: number
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/products/shop/variants',
    data: { ProductId: productId, VariantId: variantId, Delta: delta },
    params: { act: 'adjust' },
  });
  return response.data;
};

export const useAdjustVariantInventoryMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { productId: string; variantId: string; delta: number }) =>
      adjustVariantInventory(data.productId, data.variantId, data.delta),
    {
      onSuccess: (_, { productId }) => {
        queryClient.invalidateQueries([VARIANTS, { productId }]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};

/**
 * ----------------------------------------------------------------------------
 * Orders
 * ----------------------------------------------------------------------------
 */

const updateOrderStatus = async (
  orderId: string,
  status: string,
  date: string
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/customer/orders/status',
    data: { OrderId: orderId, Status: status, Date: date },
    params: { act: 'update' },
  });
  return response.data;
};

export const useUpdateOrderStatusMutation = () => {
  const { setIsAdmin } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { orderId: string; status: string; date: string }) =>
      updateOrderStatus(data.orderId, data.status, data.date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ORDERS]);
      },
      onError: (e) => adminOnError(setIsAdmin, e, navigate),
    }
  );
};
