import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { UserContextProvider } from 'context/useUserContext';
import { Root } from 'views/Root';
import ConfirmPasswordPage from 'views/ConfirmPasswordPage';
import { CustomerPage } from 'views/CustomerPage';
import { AdminPage } from 'views/AdminPage';
import LoginPage from 'views/LoginPage';
import RegisterPage from 'views/RegisterPage';
import VerifyEmailPage from 'views/VerifyEmailPage';
import ResetPasswordPage from 'views/ResetPasswordPage';
import { HomeView } from 'views/CustomerPage/views/HomeView';
import { OrdersView } from 'views/CustomerPage/views/OrdersView';
import { ShoppingCartView } from 'views/CustomerPage/views/ShoppingCartView';

import { ProductView } from 'views/AdminPage/views/ProductView';
import { ProductsTable } from 'views/AdminPage/views/ProductView/ProductsTable';
import { NewProduct } from 'views/AdminPage/views/ProductView/NewProduct';
import { Product } from 'views/AdminPage/views/ProductView/Product';
import { NewVariant } from 'views/AdminPage/views/ProductView/NewVariant';
import { Variant } from 'views/AdminPage/views/ProductView/Variant';

import { WarehouseView } from 'views/AdminPage/views/WarehouseView';

import { theme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // set stale time to 5 minutes
      staleTime: 1000 * 60 * 5,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <UserContextProvider>
        <Root />
      </UserContextProvider>
    ),
    children: [
      { index: true, element: <Navigate to="customer" /> },
      {
        path: 'customer',
        element: <CustomerPage />,
        children: [
          {
            index: true,
            element: <Navigate to="home" />,
          },
          {
            path: 'home',
            element: <HomeView />,
          },
          {
            path: 'orders',
            element: <OrdersView />,
          },
          {
            path: 'cart',
            element: <ShoppingCartView />,
          },
        ],
      },
      {
        path: 'admin',
        element: <AdminPage />,
        children: [
          {
            index: true,
            element: <Navigate to="products" />,
          },
          {
            path: 'products',
            element: <ProductView />,
            children: [
              {
                index: true,
                element: <ProductsTable />,
              },
              {
                path: 'new',
                element: <NewProduct />,
              },
              {
                path: ':productId',
                element: <Product />,
              },
              {
                path: ':productId/variants/:variantId',
                element: <Variant />,
              },
              {
                path: ':productId/variants/new',
                element: <NewVariant />,
              },
            ],
          },
          {
            path: 'warehouse',
            element: <WarehouseView />,
          },
        ],
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'confirm-password',
        element: <ConfirmPasswordPage />,
      },
      {
        path: 'verify-email',
        element: <VerifyEmailPage />,
      },
    ],
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
