import React from 'react';
import { Link, To } from 'react-router-dom';
import { Button, ButtonProps } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BackNavButtonProps = ButtonProps & {
  to: To;
  text?: string;
};

export const BackNavButton = (props: BackNavButtonProps) => {
  const { size = 'sm', to, text = 'Back', ...buttonProps } = props;

  return (
    <Link to={to}>
      <Button
        size={size}
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        {...buttonProps}
      >
        {text}
      </Button>
    </Link>
  );
};
