import React, { ReactNode } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Heading,
  LightMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { ReactComponent as Icon } from 'assets/coreograph-logo.svg';

type AccountPageLayoutProps = {
  children: ReactNode;
  title: string;
  errorMessage?: string;
  footerContent?: ReactNode;
};

const CONTAINER_WIDTH = { base: '100%', md: '80%', lg: '764px' };

export const AccountPageLayout = (props: AccountPageLayoutProps) => {
  const { title, children, errorMessage, footerContent } = props;

  const backgroundColor = useColorModeValue('lightblue', 'whiteAlpha.200');
  const footerBackgroundColor = useColorModeValue('pink', 'palepink');

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      backgroundColor={backgroundColor}
    >
      {/* CONTAINER */}
      <Flex
        as="main"
        flexDirection="column"
        alignItems="center"
        width={CONTAINER_WIDTH}
        backgroundColor="white"
        borderTopRadius="10px"
        borderTopWidth="11px"
        borderTopColor="yellow"
        padding={{ base: 8, md: '50px 110px' }}
      >
        <Box width={{ base: '100%', sm: '80%', md: '447px' }}>
          <Icon />
        </Box>

        <Heading fontWeight="normal" color="black" marginTop={12} marginBottom={8}>
          {title}
        </Heading>

        {errorMessage && (
          <LightMode>
            <Alert status="error" marginBottom={4}>
              <AlertIcon />
              <AlertDescription color="black">{errorMessage}</AlertDescription>
            </Alert>
          </LightMode>
        )}

        <Flex flexDirection="column" width="100%">
          {children}
        </Flex>
      </Flex>

      {/* FOOTER */}
      {footerContent !== undefined && (
        <Box
          width={CONTAINER_WIDTH}
          backgroundColor={footerBackgroundColor}
          borderBottomRadius="10px"
          padding={{ base: 8, md: '32px 110px' }}
        >
          {footerContent}
        </Box>
      )}
    </Flex>
  );
};
