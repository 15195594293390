import React, { ForwardedRef, forwardRef } from 'react';
import { Input, InputProps } from '@chakra-ui/react';

type AccountPageInputProps = Omit<InputProps, 'variant' | 'size' | '_placeholder'>;

export const AccountPageInput = forwardRef(
  (props: AccountPageInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <Input
        variant="filled"
        size="lg"
        _placeholder={{ opacity: 0.6, color: 'gray.500' }}
        ref={ref}
        {...props}
      />
    );
  }
);
