import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Tooltip,
  LightMode,
  useToast,
} from '@chakra-ui/react';
import { faQuestionCircle, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountPageInput } from 'components/AccountPageInput';
import { AccountPageLayout } from 'components/AccountPageLayout';

import { NewAccountFooter } from 'views/LoginPage/NewAccountFooter';
import { ConfirmResetRequestData, confirmResetRequest } from 'api/Identity';
import { getErrorMessage } from 'util/error';

const ConfirmPasswordPage = () => {
  /** REACT-ROUTER STATE */
  const {
    state,
  }: { state: { fromResetPassword?: boolean; username?: string } | undefined } =
    useLocation();
  const navigate = useNavigate();

  /** API STATE */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmResetRequestData>({
    defaultValues: {
      username: state?.username ?? '',
      new_password: '',
      confirmation_code: '',
    },
  });

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (state?.fromResetPassword) {
      toast({
        title: 'Verification code sent.',
        description:
          'An email has been sent to your address; please enter the code inside to confirm your new password.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [state, toast]);

  const onSubmit = async (data: ConfirmResetRequestData) => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      await confirmResetRequest(data);
      setIsLoading(false);
      setShowSuccessAlert(true);
      reset();
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <AccountPageLayout
      title="Confirm new password"
      errorMessage={errorMessage}
      footerContent={<NewAccountFooter />}
    >
      {showSuccessAlert && (
        <LightMode>
          <Alert status="success" marginBottom={4}>
            <AlertIcon />
            <AlertDescription color="black">
              Your password has been updated.{' '}
              <Button variant="link" onClick={() => navigate('/login')}>
                Click here to go to the login page.
              </Button>
            </AlertDescription>
          </Alert>
        </LightMode>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <LightMode>
          <Stack spacing={3} color="black">
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="email">Email address</FormLabel>
              <AccountPageInput
                id="email"
                placeholder="Email address"
                type="email"
                autoFocus={true}
                {...register('username', {
                  required: 'Please enter your email address',
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.new_password}>
              <FormLabel htmlFor="password">New password</FormLabel>
              <AccountPageInput
                id="password"
                placeholder="Password"
                type="password"
                {...register('new_password', {
                  required: 'Please enter your password',
                })}
              />
              <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.confirmation_code}>
              <FormLabel htmlFor="code">
                Verification code
                <Tooltip label="Enter the verification code that was sent to your email.">
                  <Box as="span" color="teal" marginLeft={1}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Box>
                </Tooltip>
              </FormLabel>
              <AccountPageInput
                id="code"
                placeholder="Verification code"
                {...register('confirmation_code', {
                  required: 'Please enter your verification code',
                })}
              />
              <FormErrorMessage>{errors.confirmation_code?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            leftIcon={<FontAwesomeIcon icon={faUnlock} />}
            isLoading={isLoading}
            marginTop={6}
          >
            Save new password
          </Button>
        </LightMode>
      </form>
    </AccountPageLayout>
  );
};

export default ConfirmPasswordPage;
