import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Tooltip,
  LightMode,
  useToast,
} from '@chakra-ui/react';
import { faQuestionCircle, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountPageInput } from 'components/AccountPageInput';
import { AccountPageLayout } from 'components/AccountPageLayout';
import { NewAccountFooter } from 'views/LoginPage/NewAccountFooter';
import { getErrorMessage } from 'util/error';
import { resendCodeRequest, verifyRequest, VerifyRequestData } from 'api/Identity';

const VerifyEmailPage = () => {
  /** REACT-ROUTER STATE */
  const { state }: { state: { fromRegister?: boolean; username?: string } | undefined } =
    useLocation();
  const navigate = useNavigate();

  /** API STATE */
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<VerifyRequestData>({
    defaultValues: {
      confirmation_code: '',
      username: state?.username ?? '',
    },
  });

  const toast = useToast();

  const emailValue = watch('username');
  const isResendDisabled = !emailValue;

  useEffect(() => {
    if (state?.fromRegister) {
      toast({
        title: 'Successfully registered.',
        description:
          'An email has been sent to your address; please enter the code inside to verify your account.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [state, toast]);

  const onSubmit = async (data: VerifyRequestData) => {
    setErrorMessage('');
    try {
      setIsVerifyLoading(true);
      await verifyRequest(data);
      reset();
      navigate('/login', { state: { username: data.username } });
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsVerifyLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      setIsResendLoading(true);
      await resendCodeRequest({ username: emailValue });
      toast({
        title: 'Code re-sent.',
        description: 'Another verification code has been sent to your email address.',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error resending code.',
        description: getErrorMessage(error),
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsResendLoading(false);
    }
  };

  return (
    <AccountPageLayout
      title="Verify new account"
      errorMessage={errorMessage}
      footerContent={<NewAccountFooter />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <LightMode>
          <Stack spacing={3} color="black">
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <AccountPageInput
                id="email"
                placeholder="Email"
                type="email"
                autoFocus={true}
                {...register('username', {
                  required: 'Please enter your email',
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.confirmation_code}>
              <FormLabel htmlFor="confirmation_code" alignItems="center" display="flex">
                Verification code
                <Tooltip label="Enter the verification code that was sent to your email.">
                  <Box as="span" color="teal" marginLeft={1}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Box>
                </Tooltip>
                <Tooltip
                  label="Please enter the region and email of your account."
                  isDisabled={!isResendDisabled}
                >
                  <Box>
                    <Button
                      isDisabled={isResendDisabled}
                      marginLeft={3}
                      onClick={resendCode}
                      color="black"
                      size="sm"
                      isLoading={isResendLoading}
                    >
                      Resend code
                    </Button>
                  </Box>
                </Tooltip>
              </FormLabel>
              <AccountPageInput
                id="confirmation_code"
                placeholder="Verification code"
                {...register('confirmation_code', {
                  required: 'Please enter your verification code',
                })}
              />
              <FormErrorMessage>{errors.confirmation_code?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <ButtonGroup spacing={6} marginTop={6}>
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              leftIcon={<FontAwesomeIcon icon={faUnlock} />}
              isLoading={isVerifyLoading}
            >
              Verify
            </Button>
          </ButtonGroup>
        </LightMode>
      </form>
    </AccountPageLayout>
  );
};

export default VerifyEmailPage;
