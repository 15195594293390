import React, { useMemo, useState } from 'react';
import { Card, CardBody, Heading, Image, Stack } from '@chakra-ui/react';
import { useVariantsOfProductQuery } from 'api/Customer';
import { ProductEntry } from 'types';
import { ProductModal } from './ProductModal';

type ProductCardProps = {
  productEntry: ProductEntry;
};

export const ProductCard = (props: ProductCardProps) => {
  const { productEntry } = props;
  const { Description: description, ProductId: productId } = productEntry;
  const { data: productVariants } = useVariantsOfProductQuery(productId);

  const firstImagePath = useMemo(() => {
    if (productVariants && productVariants.length > 0) {
      return `${process.env.REACT_APP_IMAGE_URL}/${productVariants[0].Image}`;
    }
    return 'https://via.placeholder.com/150';
  }, [productVariants]);

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);

  return (
    <>
      <Card
        flex={1}
        textAlign="center"
        fontSize="80%"
        onClick={() => setIsProductModalOpen(true)}
        _hover={{ background: 'blue.800', cursor: 'pointer' }}
      >
        <CardBody>
          <Stack gap={2}>
            <Image
              src={firstImagePath}
              alt={description}
              borderRadius="lg"
              boxSize="sm"
              fit="cover"
            />
            <Heading size="md" overflowWrap="break-word">
              {description}
            </Heading>
          </Stack>
        </CardBody>
      </Card>
      <ProductModal
        productEntry={productEntry}
        productVariants={productVariants}
        isOpen={isProductModalOpen}
        onClose={() => setIsProductModalOpen(false)}
      />
    </>
  );
};
