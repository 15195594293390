import React from 'react';
import { Skeleton, Text } from '@chakra-ui/react';

import { useProductDetailQuery } from 'api/Customer';

import { ProductDetailsForm } from './ProductDetailsForm';

type ProductDetailsTabProps = {
  productId: string;
};

export const ProductDetailsTab = (props: ProductDetailsTabProps) => {
  const { productId } = props;

  const { data: product, isError, isLoading } = useProductDetailQuery(productId);

  if (isLoading) {
    return <Skeleton height="1000px" />;
  }

  if (isError) {
    return <Text>Error</Text>;
  }

  return <ProductDetailsForm product={product} />;
};
