import React, { ReactNode } from 'react';

import { ButtonGroup, Flex, IconButton, Menu, MenuButton } from '@chakra-ui/react';

import { QuestionIcon } from '@chakra-ui/icons';
import { ProfileMenu } from 'components/ProfileMenu';

export const HEADER_HEIGHT = '10vh';

type HeaderProps = {
  children?: ReactNode;
};

const placeholder = <QuestionIcon />;

export const Header = (props: HeaderProps) => {
  const { children } = props;

  const isMobile = false;

  return (
    <Flex
      as="header"
      height={HEADER_HEIGHT}
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={6}
      paddingRight={6}
    >
      {children}

      {isMobile ? (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={placeholder}
            size="sm"
            variant="circle"
            aria-label="More"
          />
        </Menu>
      ) : (
        <ButtonGroup size="md" spacing={2}>
          <ProfileMenu />
        </ButtonGroup>
      )}
    </Flex>
  );
};
