import React, { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  LightMode,
  Stack,
  useColorModeValue,
  Tooltip,
  Box,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { faArrowRight, faInfoCircle, faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AccountPageInput } from 'components/AccountPageInput';
import { AccountPageLayout } from 'components/AccountPageLayout';
import { RegisterRequestData, signUpRequest } from 'api/Identity';

import {
  EIGHT_OR_MORE,
  ONE_LOWERCASE,
  ONE_UPPERCASE,
  ONE_NUMBER,
  ONE_SPECIAL,
} from 'util/regex';
import { getErrorMessage } from 'util/error';

import { theme } from 'theme';
import { UserTypeDropDown } from './components/UserTypeDropDown';

const passwordValidator = (value: string) => {
  if (!EIGHT_OR_MORE.test(value)) return 'Password must be 8 characters or longer';
  if (!ONE_LOWERCASE.test(value))
    return 'Password must contain at least one lowercase letter';
  if (!ONE_UPPERCASE.test(value))
    return 'Password must contain at least one uppercase letter';
  if (!ONE_NUMBER.test(value)) return 'Password must contain at least one number';
  if (!ONE_SPECIAL.test(value))
    return 'Password must contain at least one special character';
};

const RegisterPage = () => {
  /** REACT-ROUTER STATE */
  const navigate = useNavigate();

  /** API STATE */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /** FORM STATE */
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<RegisterRequestData>({
    defaultValues: {
      username: '',
      password: '',
      password2: '',
      UserType: undefined,
    },
  });

  const password = watch('password');
  const confirmPasswordValidator = (value: string) => {
    if (value !== password) return 'Passwords must match';
  };

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const onSubmit = async (data: RegisterRequestData) => {
    setErrorMessage('');
    setShowSuccessAlert(false);
    setIsLoading(true);

    try {
      await signUpRequest(data);

      setIsLoading(false);
      setShowSuccessAlert(true);

      reset();

      navigate('/verify-email', {
        state: { fromRegister: true, username: data.username },
      });
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(getErrorMessage(error));
    }
  };

  /** CHAKRA UI COLOR STATE */
  const buttonColor = useColorModeValue('gray', 'blackAlpha');

  return (
    <AccountPageLayout
      title="Create your account"
      errorMessage={errorMessage}
      footerContent={
        <Button
          onClick={handleLoginClick}
          colorScheme={buttonColor}
          size="lg"
          leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
        >
          Go to login
        </Button>
      }
    >
      <LightMode>
        {showSuccessAlert && (
          <Alert status="success" marginBottom={4}>
            <AlertIcon />
            <AlertDescription color="black">
              An email has been sent to your address; please follow the steps inside to
              verify your account.{' '}
              <Button variant="link" onClick={handleLoginClick}>
                Click here to go to the login page.
              </Button>
            </AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} color="black">
            <FormControl
              isInvalid={!!errors.UserType}
              width={{ base: undefined, lg: '50%' }}
            >
              <FormLabel htmlFor="userType">User Type</FormLabel>
              <UserTypeDropDown
                id="userType"
                autoFocus={true}
                {...register('UserType', {
                  required: 'Please select a region',
                })}
              />
              <FormErrorMessage>{errors.UserType?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="email">Email address</FormLabel>
              <AccountPageInput
                id="email"
                placeholder="Email address"
                type="email"
                {...register('username', {
                  required: 'Please enter your email address',
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <FormLabel display="flex" alignItems="center" htmlFor="password">
                <Text marginRight="5px">Password</Text>
                <Tooltip
                  label={
                    <>
                      <Text>Password must contain:</Text>
                      <UnorderedList>
                        <ListItem>8 or more characters</ListItem>
                        <ListItem>At least one lowercase letter</ListItem>
                        <ListItem>At least one uppercase letter</ListItem>
                        <ListItem>At least one number</ListItem>
                        <ListItem>At least one special character</ListItem>
                      </UnorderedList>
                    </>
                  }
                >
                  <Box>
                    <FontAwesomeIcon icon={faInfoCircle} color={theme.colors.teal} />
                  </Box>
                </Tooltip>
              </FormLabel>
              <AccountPageInput
                id="password"
                placeholder="Password"
                type="password"
                {...register('password', {
                  required: 'Please enter a password',
                  validate: passwordValidator,
                })}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password2}>
              <FormLabel htmlFor="password2">Confirm password</FormLabel>
              <AccountPageInput
                id="password2"
                placeholder="Confirm password"
                type="password"
                {...register('password2', {
                  required: 'Please confirm your password',
                  validate: confirmPasswordValidator,
                })}
              />
              <FormErrorMessage>{errors.password2?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            leftIcon={<FontAwesomeIcon icon={faSignIn} />}
            isLoading={isLoading}
            marginTop={6}
          >
            Register
          </Button>
        </form>
      </LightMode>
    </AccountPageLayout>
  );
};

export default RegisterPage;
