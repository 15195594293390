import React from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationBar, NavigationBarLink } from 'components/NavigationBar';
import { PageLayout } from 'components/PageLayout';

const CUSTOMER_LINKS: NavigationBarLink[] = [
  { to: 'home', text: 'Home' },
  { to: 'orders', text: 'My Orders' },
  { to: 'cart', text: 'My Cart' },
];

export const CustomerPage = () => {
  return (
    <PageLayout headerContent={<NavigationBar links={CUSTOMER_LINKS} />}>
      <Outlet />
    </PageLayout>
  );
};
