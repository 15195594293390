import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestHeaders } from 'axios';

import { useUserContext } from 'context/useUserContext';
import { customerOnError, getRequestHeaders } from 'api/util';
import { storeJWTs } from 'services/TokenService';
import {
  BrandEntry,
  BrandEntrySchema,
  BRANDS,
  CART,
  CartCheckout,
  CartLineItemEntry,
  CartLineItemEntrySchema,
  CustomerEntry,
  CustomerEntrySchema,
  CustomerOrderEntry,
  CustomerOrderEntrySchema,
  CUSTOMERS,
  CustomerStatusDateEntry,
  CustomerStatusDateEntrySchema,
  OrderLineItemEntry,
  OrderLineItemEntrySchema,
  ORDERS,
  ProductEntry,
  ProductEntrySchema,
  PRODUCTS,
  ProductVariantEntry,
  ProductVariantEntrySchema,
  VARIANTS,
} from 'types';
import { getEcommerceUrl } from 'util/url';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
  baseURL: getEcommerceUrl(),
  method: 'post',
  withCredentials: true,
});

// Cast as AxiosRequestHeaders (temporary workaround)
// https://github.com/axios/axios/issues/5034
axiosInstance.interceptors.request.use(
  (config) => {
    const splitUrl = config.url?.split('/');
    const isProtected = splitUrl ? splitUrl[2] === 'public' : false;
    return {
      ...config,
      headers: getRequestHeaders(
        isProtected ? 'unprotected' : 'protected'
      ) as AxiosRequestHeaders,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    storeJWTs({
      idToken: response.headers.id_token,
      accessToken: response.headers.access_token,
    });
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * ----------------------------------------------------------------------------
 * Product catalog
 * ----------------------------------------------------------------------------
 */

const fetchProductDetail = async (productId: string): Promise<ProductEntry> => {
  const response = await axiosInstance.request({
    url: '/products/public',
    data: { ProductId: productId },
    params: { act: 'get' },
  });
  return ProductEntrySchema.parse(response.data);
};

export const useProductDetailQuery = (productId: string) => {
  return useQuery({
    queryKey: [PRODUCTS, { productId }],
    queryFn: () => fetchProductDetail(productId),
  });
};

const fetchBrandDetail = async (brand: string): Promise<BrandEntry> => {
  const response = await axiosInstance.request({
    url: '/products/public/brands',
    data: { Brand: brand },
    params: { act: 'get' },
  });
  return BrandEntrySchema.parse(response.data);
};

export const useBrandDetailQuery = (brand: string) => {
  return useQuery({
    queryKey: [BRANDS, { brand }],
    queryFn: () => fetchBrandDetail(brand),
  });
};

const fetchVariantsOfProduct = async (
  productId: string
): Promise<ProductVariantEntry[]> => {
  const response = await axiosInstance.request({
    url: '/products/public/variants',
    data: { ProductId: productId },
    params: { act: 'list' },
  });
  return z.array(ProductVariantEntrySchema).parse(response.data);
};

export const useVariantsOfProductQuery = (productId: string) => {
  return useQuery({
    queryKey: [VARIANTS, { productId }],
    queryFn: () => fetchVariantsOfProduct(productId),
  });
};

const fetchProductsByBrand = async (brand: string): Promise<ProductEntry[]> => {
  const response = await axiosInstance.request({
    url: '/products/public',
    data: { Brand: brand },
    params: { act: 'listbrand' },
  });

  return z.array(ProductEntrySchema).parse(response.data);
};

export const useProductsByBrandQuery = (brand: string) => {
  return useQuery({
    queryKey: [PRODUCTS, { brand }],
    queryFn: () => fetchProductsByBrand(brand),
  });
};

const fetchProductsByCategory = async (categoryId: string): Promise<ProductEntry[]> => {
  const response = await axiosInstance.request({
    url: '/products/public',
    data: { Category: categoryId },
    params: { act: 'listcat' },
  });

  return z.array(ProductEntrySchema).parse(response.data);
};

export const useProductsByCategoryQuery = (categoryId: string) => {
  return useQuery({
    queryKey: [PRODUCTS, { categoryId }],
    queryFn: () => fetchProductsByCategory(categoryId),
  });
};

/**
 * ----------------------------------------------------------------------------
 * Profile
 * ----------------------------------------------------------------------------
 */

const fetchCustomerDetail = async (): Promise<CustomerEntry> => {
  const response = await axiosInstance.request({
    url: '/customer',
    params: { act: 'get' },
  });

  return CustomerEntrySchema.parse(response.data);
};

export const useCustomerDetailQuery = () => {
  const { setIsAuthed } = useUserContext();
  return useQuery({
    queryKey: [CUSTOMERS],
    queryFn: () => fetchCustomerDetail(),
    onError: (e) => customerOnError(setIsAuthed, e),
  });
};

const addAddress = async (
  addressName: string,
  address: string
): Promise<Record<string, string>> => {
  const response = await axiosInstance.request({
    url: '/customer/addresses',
    data: {
      AddressName: addressName,
      Address: address,
    },
    params: { act: 'add' },
  });
  return z.record(z.string()).parse(response.data);
};

export const useAddAddressMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { addressName: string; address: string }) =>
      addAddress(data.addressName, data.address),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CUSTOMERS]);
      },
      onError: (e) => customerOnError(setIsAuthed, e, navigate),
    }
  );
};

const deleteAddress = async (addressName: string): Promise<Record<string, string>> => {
  const response = await axiosInstance.request({
    url: '/customer/addresses',
    data: { AddressName: addressName },
    params: { act: 'delete' },
  });
  return z.record(z.string()).parse(response.data);
};

export const useDeleteAddressMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation((addressName: string) => deleteAddress(addressName), {
    onSuccess: () => {
      queryClient.invalidateQueries([CUSTOMERS]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

const addPaymentMethod = async (
  paymentName: string,
  payment: string
): Promise<Record<string, string>> => {
  const response = await axiosInstance.request({
    url: '/customer/payment',
    data: {
      PaymentName: paymentName,
      Payment: payment,
    },
    params: { act: 'add' },
  });
  return z.record(z.string()).parse(response.data);
};

export const useAddPaymentMethodMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { paymentName: string; payment: string }) =>
      addPaymentMethod(data.paymentName, data.payment),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CUSTOMERS]);
      },
      onError: (e) => customerOnError(setIsAuthed, e, navigate),
    }
  );
};

const deletePaymentMethod = async (
  paymentName: string
): Promise<Record<string, string>> => {
  const response = await axiosInstance.request({
    url: '/customer/payment',
    data: { PaymentName: paymentName },
    params: { act: 'delete' },
  });
  return z.record(z.string()).parse(response.data);
};

export const useDeletePaymentMethodMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation((paymentName: string) => deletePaymentMethod(paymentName), {
    onSuccess: () => {
      queryClient.invalidateQueries([CUSTOMERS]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

/**
 * ----------------------------------------------------------------------------
 * Cart
 * ----------------------------------------------------------------------------
 */

const addProductVariantToCartPayloadSchema = ProductVariantEntrySchema.pick({
  ProductId: true,
  VariantId: true,
}).extend({
  Quantity: z.number(),
});
export type AddProductVariantToCartPayload = z.infer<
  typeof addProductVariantToCartPayloadSchema
>;

// this returns empty string
const addProductVariantToCart = async (
  data: AddProductVariantToCartPayload
): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/customer/cart/item',
    data,
    params: { act: 'add' },
  });
  return response.data;
};

export const useAddProductVariantToCartMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (data: AddProductVariantToCartPayload) => addProductVariantToCart(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CART]);
      },
      onError: (e) => customerOnError(setIsAuthed, e, navigate),
    }
  );
};

const deleteProductVariantFromCart = async (variantId: string): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/customer/cart/item',
    data: { VariantId: variantId },
    params: { act: 'delete' },
  });
  return response.data;
};

export const useDeleteProductVariantFromCartMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation((variantId: string) => deleteProductVariantFromCart(variantId), {
    onSuccess: () => {
      queryClient.invalidateQueries([CART]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

const fetchProductVariantsInCart = async (): Promise<CartLineItemEntry[]> => {
  const response = await axiosInstance.request({
    url: '/customer/cart/item',
    params: { act: 'list' },
  });

  return z.array(CartLineItemEntrySchema).parse(response.data);
};

export const useProductVariantsInCartQuery = () => {
  const { setIsAuthed } = useUserContext();
  return useQuery({
    queryKey: [CART],
    queryFn: () => fetchProductVariantsInCart(),
    onError: (e) => customerOnError(setIsAuthed, e),
  });
};

const emptyCart = async (): Promise<void> => {
  const response = await axiosInstance.request({
    url: '/customer/cart',
    params: { act: 'empty' },
  });
  return response.data;
};

export const useEmptyCartMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(() => emptyCart(), {
    onSuccess: () => {
      queryClient.invalidateQueries([CART]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

// returns orderId
const cartCheckout = async (data: CartCheckout): Promise<string> => {
  const { UserName, Email, Address, PaymentMethod } = data;
  const response = await axiosInstance.request({
    url: '/customer/cart',
    data: {
      UserName,
      Email,
      Address,
      PaymentMethod,
    },
    params: { act: 'checkout' },
  });
  return response.data;
};

export const useCartCheckoutMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation((data: CartCheckout) => cartCheckout(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([CART]);
      queryClient.invalidateQueries([ORDERS]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

/**
 * ----------------------------------------------------------------------------
 * Orders
 * ----------------------------------------------------------------------------
 */

// returns empty string
const deleteOrder = async (orderId: string): Promise<string> => {
  const response = await axiosInstance.request({
    url: '/customer/orders',
    data: {
      OrderId: orderId,
    },
    params: { act: 'delete' },
  });
  return response.data;
};

export const useDeleteOrderMutation = () => {
  const { setIsAuthed } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation((orderId: string) => deleteOrder(orderId), {
    onSuccess: () => {
      queryClient.invalidateQueries([ORDERS]);
      queryClient.invalidateQueries([PRODUCTS]);
    },
    onError: (e) => customerOnError(setIsAuthed, e, navigate),
  });
};

const fetchLineItemsInOrder = async (orderId: string): Promise<OrderLineItemEntry[]> => {
  const response = await axiosInstance.request({
    url: '/customer/orders/item',
    data: {
      OrderId: orderId,
    },
    params: { act: 'list' },
  });

  return z.array(OrderLineItemEntrySchema).parse(response.data);
};

export const useLineItemsInOrderQuery = (orderId: string) => {
  const { setIsAuthed } = useUserContext();
  return useQuery({
    queryKey: [ORDERS, { orderId }],
    queryFn: () => fetchLineItemsInOrder(orderId),
    onError: (e) => customerOnError(setIsAuthed, e),
  });
};

const fetchOrdersOfCustomer = async (): Promise<CustomerOrderEntry[]> => {
  const response = await axiosInstance.request({
    url: '/customer/orders',
    params: { act: 'list' },
  });

  return z.array(CustomerOrderEntrySchema).parse(response.data);
};

export const useOrdersOfCustomerQuery = () => {
  const { setIsAuthed } = useUserContext();
  return useQuery({
    queryKey: [ORDERS],
    queryFn: () => fetchOrdersOfCustomer(),
    onError: (e) => customerOnError(setIsAuthed, e),
  });
};

export const fetchOrdersByStatusDate = async (
  status: string,
  dateFrom: string,
  dateTo: string
): Promise<CustomerStatusDateEntry[]> => {
  const response = await axiosInstance.request({
    url: '/customer/orders/status',
    data: {
      Status: status,
      DateFrom: dateFrom,
      DateTo: dateTo,
    },
    params: { act: 'list' },
  });
  return z.array(CustomerStatusDateEntrySchema).parse(response.data);
};

export const useOrdersByStatusDateQuery = (
  status: string,
  dateFrom: string,
  dateTo: string
) => {
  const { setIsAuthed } = useUserContext();

  return useQuery({
    queryKey: [ORDERS, { status, dateFrom, dateTo }],
    queryFn: () => fetchOrdersByStatusDate(status, dateFrom, dateTo),
    onError: (e) => customerOnError(setIsAuthed, e),
  });
};
