import React, { useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { WarehouseEntrySchema, WarehouseEntry } from 'types';
import { useAddWHItemMutation } from 'api/Admin/warehouse';

type NewWarehouseItemModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>;

export const NewWarehouseItemModal = (props: NewWarehouseItemModalProps) => {
  const { isOpen, onClose } = props;

  const defaultValues: WarehouseEntry = {
    VariantId: '',
    WHLocId: '',
    SKU: '',
    BinLocId: '',
    Quantity: 0,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<WarehouseEntry>({
    defaultValues,
    resolver: zodResolver(WarehouseEntrySchema),
  });

  const { mutate, isLoading, reset: mutationReset } = useAddWHItemMutation();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
    mutationReset();
  }, [isOpen, reset, mutationReset]);

  const toast = useToast();

  const onSubmit = (data: WarehouseEntry) => {
    const newWHItem = {
      ...data,
    };

    mutate(newWHItem, {
      onSuccess: () => {
        toast({
          title: 'Added new warehouse item',
          status: 'success',
          isClosable: true,
        });
        onClose();
      },
      onError: () => {
        toast({
          title: 'Unable to add warehouse item',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>New Warehouse Item</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {Object.keys(WarehouseEntrySchema.shape).map((field) => {
              switch (field) {
                case 'VariantId':
                case 'WHLocId':
                case 'SKU':
                case 'BinLocId':
                case 'Quantity':
                  return (
                    <FormControl key={field} isInvalid={!!errors[field]}>
                      <FormLabel htmlFor={field}>{field}</FormLabel>
                      <Input
                        id={field}
                        {...register(field, {
                          ...(field === 'Quantity' && { valueAsNumber: true }),
                        })}
                        {...(field === 'Quantity' && { type: 'number' })}
                      />
                      <FormErrorMessage>{errors[field]?.message}</FormErrorMessage>
                    </FormControl>
                  );
                default:
                  return null;
              }
            })}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button type="submit" colorScheme="blue" isDisabled={!isDirty || isLoading}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
