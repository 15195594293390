import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';

export const ProductView = () => {
  return (
    <Flex flexDirection="column" height="90vh">
      <Outlet />
    </Flex>
  );
};
