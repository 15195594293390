import React, { useState } from 'react';
import {
  Flex,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Button,
  useToast,
  Divider,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { sub } from 'date-fns';

import { useUserContext } from 'context/useUserContext';
import { useDeleteOrderMutation, useOrdersByStatusDateQuery } from 'api/Customer';
import { parseStatusDate } from 'util/string';
import { OrderLineItems } from './OrderLineItems';

const STATUSES = [{ name: 'ORDER_PLACED', value: 'Orders placed' }] as const;
const DEFAULT_STATUS = STATUSES[0];

const DATES = [
  { name: 'All time', value: new Date(-8640000000000000) },
  {
    name: 'Last week',
    value: sub(new Date(), { weeks: 1 }),
  },
  { name: 'Last month', value: sub(new Date(), { months: 1 }) },
  { name: 'Last year', value: sub(new Date(), { years: 1 }) },
] as const;
const DEFAULT_DATE = DATES[0];

export const OrdersView = () => {
  const { isAuthed } = useUserContext();
  const [dateFrom, setDateFrom] = useState(DEFAULT_DATE.value);
  const [dateTo, setDateTo] = useState(new Date());
  const [status, setStatus] = useState(DEFAULT_STATUS.name);

  const toast = useToast();
  const { data = [], isFetching } = useOrdersByStatusDateQuery(
    status,
    dateFrom.getTime().toString(),
    dateTo.getTime().toString()
  );

  const isOrdersEmpty = data.length === 0;

  const { mutate, isLoading } = useDeleteOrderMutation();

  const handleCancelOrder = (orderId: string) => {
    mutate(orderId, {
      onSuccess: () => {
        toast({
          title: 'Canceled order',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Unable to canceled order',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <>
      <Flex justifyContent="start" alignItems="center" mb={4}>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Filter by
          </MenuButton>
          <MenuList>
            <MenuOptionGroup title="Dates" type="radio" defaultValue={DEFAULT_DATE.name}>
              {DATES.map(({ name, value }) => {
                return (
                  <MenuItemOption
                    key={name}
                    value={name}
                    onClick={() => {
                      setDateTo(new Date());
                      setDateFrom(value);
                    }}
                  >
                    {name}
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              title="Status"
              type="radio"
              defaultValue={DEFAULT_STATUS.value}
            >
              {STATUSES.map(({ name, value }) => {
                return (
                  <MenuItemOption
                    key={name}
                    value={value}
                    onClick={() => {
                      setStatus(name);
                    }}
                  >
                    {value}
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>

      <Flex flexDirection="column" gap={3} height="75vh">
        {isFetching ? (
          <Stack gap={5}>
            {new Array(15).fill(0).map(() => (
              <Skeleton height="3vh" />
            ))}
          </Stack>
        ) : isOrdersEmpty || !isAuthed ? (
          <>
            <Divider />
            <Text>No orders</Text>
          </>
        ) : (
          <Accordion allowToggle={true} overflowY="auto">
            {data.map((orderEntry) => {
              const { OrderId, Email, PaymentMethod, Address, StatusDate } = orderEntry;
              const parsedStatusDate = parseStatusDate(StatusDate);
              return (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left" p={2} display="flex">
                        <Text>{orderEntry.OrderId}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box
                      borderRadius="lg"
                      borderWidth="2px"
                      display="flex"
                      flexDir="column"
                      gap={3}
                    >
                      <Table variant="simple" size="md">
                        <Thead>
                          <Tr>
                            <Th>Order Details</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>Email: </Td>
                            <Td>{Email}</Td>
                          </Tr>
                          <Tr>
                            <Td>Payment Method:</Td>
                            <Td>{PaymentMethod}</Td>
                          </Tr>
                          <Tr>
                            <Td>Address:</Td>
                            <Td>{Address}</Td>
                          </Tr>
                          <Tr>
                            <Td>Status:</Td>
                            <Td>{parsedStatusDate?.status}</Td>
                          </Tr>
                          <Tr>
                            <Td>Date:</Td>
                            <Td>{parsedStatusDate?.date.toLocaleDateString()}</Td>
                          </Tr>
                        </Tbody>
                      </Table>

                      <OrderLineItems orderId={OrderId} />
                      <Button
                        isLoading={isLoading}
                        alignSelf="center"
                        mb={3}
                        colorScheme="red"
                        onClick={() => handleCancelOrder(OrderId)}
                      >
                        Cancel Order
                      </Button>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        )}
      </Flex>
    </>
  );
};
