import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  ButtonGroup,
  Button,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAddProductMutation } from 'api/Admin';
import { ProductEntrySchema } from 'types';
import { ProductPageLayout } from './ProductPageLayout';

const NewProductFormSchema = ProductEntrySchema.omit({
  ProductId: true, // generated by backend,
}).extend({
  // Override Variables to a string instead of Set
  // Add transform to convert comma-delimited string to Set after parsing/validation
  Variables: z.string().transform((val) => val.split(',')),
});

type NewProductForm = z.infer<typeof NewProductFormSchema>;

export const NewProduct = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<NewProductForm>({
    resolver: zodResolver(NewProductFormSchema),
  });

  const productMutation = useAddProductMutation();
  const { mutate, isLoading } = productMutation;

  const toast = useToast();

  const onSubmit = (data: NewProductForm) => {
    mutate(data, {
      onSuccess: () => {
        toast({
          title: 'Product added',
          status: 'success',
          isClosable: true,
        });

        navigate('..');
      },
      onError: () => {
        toast({
          title: 'Product creation failed',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <ProductPageLayout title="New product" hasBackButton={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.Category}>
          <FormLabel htmlFor="category">Category</FormLabel>
          <Input id="category" {...register('Category')} />
          <FormErrorMessage>{errors.Category?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.Brand}>
          <FormLabel htmlFor="brand">Brand</FormLabel>
          <Input id="brand" {...register('Brand')} />
          <FormErrorMessage>{errors.Brand?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.Description}>
          <FormLabel htmlFor="description">Description</FormLabel>
          <Textarea id="description" {...register('Description')} />
          <FormErrorMessage>{errors.Description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.Logo}>
          <FormLabel htmlFor="logo">Logo (path to file)</FormLabel>
          <Input id="logo" {...register('Logo')} />
          <FormErrorMessage>{errors.Logo?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.Brochure}>
          <FormLabel htmlFor="brochure">Brochure (path to file)</FormLabel>
          <Input id="brochure" {...register('Brochure')} />
          <FormErrorMessage>{errors.Brochure?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.Variables}>
          <FormLabel htmlFor="variables">Variables (comma-delimited list)</FormLabel>
          <Input id="variables" {...register('Variables')} />
          <FormErrorMessage>{errors.Variables?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup marginTop={4}>
          <Button type="submit" colorScheme="blue" isDisabled={!isDirty || isLoading}>
            Save
          </Button>
        </ButtonGroup>
      </form>
    </ProductPageLayout>
  );
};
