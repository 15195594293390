import React, { ForwardedRef, forwardRef } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';

type UserTypeDropDownProps = Omit<SelectProps, 'variant' | 'size' | 'color'>;

export const UserTypeDropDown = forwardRef(
  (props: UserTypeDropDownProps, ref: ForwardedRef<HTMLSelectElement>) => {
    return (
      <Select variant="filled" size="lg" color="gray.500" ref={ref} {...props}>
        <option value={undefined}>Customer</option>
        <option value="admin">Admin</option>
      </Select>
    );
  }
);
