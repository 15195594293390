import React, { useState } from 'react';
import {
  Button,
  Heading,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { WarehouseEntry } from 'types';

import { useDeleteWHItemMutation, useWHItemDetailQuery } from 'api/Admin/warehouse';
import { ConfirmationModal } from 'components/ConfirmationModal';

import { PopoverEditButton } from './PopoverEditButton';
import { FilterInput } from './FilterInput';

type DeleteWHItemMutationPayload = {
  variantId: string;
  whLocId: string;
};

export const WarehouseTable = () => {
  const { data = [], isInitialLoading } = useWHItemDetailQuery();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const [deleteItem, setDeleteItem] = useState<DeleteWHItemMutationPayload>({
    variantId: '',
    whLocId: '',
  });

  const toast = useToast();
  const { mutate, status } = useDeleteWHItemMutation();

  const handleDelete = (mutateData: DeleteWHItemMutationPayload) => {
    mutate(mutateData, {
      onSuccess: () => {
        toast({
          title: 'Deleted warehouse item',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Unable to delete item',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  const columnHelper = createColumnHelper<WarehouseEntry>();

  const columns = [
    columnHelper.accessor('VariantId', {
      header: 'Variant Identifier',
      cell: (c) => c.getValue(),
    }),
    columnHelper.accessor('WHLocId', {
      header: 'Warehouse location identifier',
      cell: (c) => c.getValue(),
    }),
    columnHelper.accessor('SKU', {
      header: 'Stock keeping unit',
      cell: (c) => c.getValue(),
    }),
    columnHelper.accessor('BinLocId', {
      header: 'Bin location identifier',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (c) => (
        <PopoverEditButton warehouseEntry={c.row.original} isQuantity={false} />
      ),
    }),
    columnHelper.accessor('Quantity', {
      header: 'Quantity',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (c) => (
        <PopoverEditButton warehouseEntry={c.row.original} isQuantity={true} />
      ),
      filterFn: (row, _, val) => row.original.Quantity.toString().includes(val),
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (c) => (
        <Button
          size="xs"
          colorScheme="red"
          onClick={() => {
            setDeleteItem({
              variantId: c.row.original.VariantId,
              whLocId: c.row.original.WHLocId,
            });
            onDeleteModalOpen();
          }}
        >
          Delete
        </Button>
      ),
    }),
  ];

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    columns,
    data,
    state: { columnFilters },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isInitialLoading)
    return (
      <Stack gap={5}>
        {new Array(15).fill(0).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={i} height="3vh" />
        ))}
      </Stack>
    );

  if (!data.length) return <>No items</>;

  return (
    <>
      <TableContainer overflowY="scroll">
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id}>
                      <Stack>
                        <Heading size="xs">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Heading>
                        {header.column.getCanFilter() ? (
                          <FilterInput column={header.column} />
                        ) : null}
                      </Stack>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getFilteredRowModel().rows.length ? (
              table.getFilteredRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td>No items found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete warehouse item"
        actionDescription={
          <>
            Are you sure you want to delete{' '}
            <Tag colorScheme="teal">{deleteItem.variantId}</Tag> ?
          </>
        }
        onClose={onDeleteModalClose}
        actionButtonText="Delete"
        handleAction={() => handleDelete(deleteItem)}
        actionButtonColor="red"
        status={status}
      />
    </>
  );
};
