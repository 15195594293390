import React from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { ProductDetailsTab } from './ProductDetailsTab';
import { ProductVariantsTab } from './ProductVariantsTab';
import { ProductPageLayout } from '../ProductPageLayout';

export const Product = () => {
  const { productId } = useParams();

  if (!productId) return null;

  return (
    <ProductPageLayout title={`Product: ${productId}`} hasBackButton={true}>
      <Tabs>
        <TabList>
          <Tab>Product details</Tab>
          <Tab>Variants</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ProductDetailsTab productId={productId} />
          </TabPanel>
          <TabPanel>
            <ProductVariantsTab productId={productId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ProductPageLayout>
  );
};
