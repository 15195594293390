import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  ButtonGroup,
  Button,
  LightMode,
  Stack,
} from '@chakra-ui/react';
import { faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import { AccountPageInput } from 'components/AccountPageInput';
import { AccountPageLayout } from 'components/AccountPageLayout';
import { NewAccountFooter } from 'views/LoginPage/NewAccountFooter';
import { resetPasswordRequest, ResetPasswordRequestData } from 'api/Identity';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'util/error';

const ResetPasswordPage = () => {
  /** REACT-ROUTER STATE */
  const navigate = useNavigate();

  /** API STATE */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordRequestData>({
    defaultValues: {
      username: '',
    },
  });

  const onSubmit = async (data: ResetPasswordRequestData) => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      await resetPasswordRequest(data);
      setIsLoading(false);
      reset();
      // Send user to confirmation page
      navigate('/confirm-password', {
        state: {
          fromResetPassword: true,
          username: data.username,
        },
      });
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <AccountPageLayout
      title="Reset password"
      errorMessage={errorMessage}
      footerContent={<NewAccountFooter />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <LightMode>
          <Stack spacing={3} color="black">
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="email">Email address</FormLabel>
              <AccountPageInput
                id="email"
                placeholder="Email address"
                type="email"
                autoFocus={true}
                {...register('username', {
                  required: 'Please enter your email address',
                })}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <ButtonGroup spacing={6} marginTop={6}>
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<FontAwesomeIcon icon={faUnlock} />}
              size="lg"
              isLoading={isLoading}
            >
              Reset password
            </Button>
            <Button variant="link" onClick={() => navigate('/login')}>
              Back to login
            </Button>
          </ButtonGroup>
        </LightMode>
      </form>
    </AccountPageLayout>
  );
};

export default ResetPasswordPage;
