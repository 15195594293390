import React from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { z } from 'zod';

import {
  useUpdateProductVariantMutation,
  useAdjustVariantInventoryMutation,
} from 'api/Admin';
import { ProductVariantEntrySchema, ProductVariantEntry } from 'types';

const EditVariantFormSchema = ProductVariantEntrySchema.extend({
  Combination: z.array(z.array(z.string(), z.string())),
});
type EditVariantForm = z.infer<typeof EditVariantFormSchema>;

type VariantFormProps = {
  variant: ProductVariantEntry;
};

export const VariantForm = (props: VariantFormProps) => {
  const { variant } = props;

  /*
  const defaultValues: EditVariantForm = useMemo(() => {
    return { ...variant, Combination: Object.entries(variant.Combination) };
  }, [variant]);
  */
  const defaultValues: EditVariantForm = {
    ...variant,
    Combination: Object.entries(variant.Combination),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<EditVariantForm>({
    defaultValues,
    resolver: zodResolver(EditVariantFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Combination',
  });

  const { mutate: updateVariant, isLoading } = useUpdateProductVariantMutation();

  const { mutate: adjustInventory } = useAdjustVariantInventoryMutation();

  const toast = useToast();

  const onSubmit = (data: EditVariantForm) => {
    const updatedVariant: ProductVariantEntry = {
      ...data,
      Combination: Object.fromEntries(data.Combination),
    };
    const {
      ProductId: productId,
      VariantId: variantId,
      Available: updatedAvailable,
    } = updatedVariant;
    const delta = updatedAvailable - defaultValues.Available;

    updateVariant(updatedVariant, {
      onSuccess: () => {
        if (delta !== 0) {
          adjustInventory(
            { productId, variantId, delta },
            {
              onSuccess: () => {
                toast({
                  title: 'Variant updated',
                  status: 'success',
                  isClosable: true,
                });
                reset(data);
              },
              onError: () => {
                toast({
                  title: 'Variant update failed',
                  status: 'error',
                  isClosable: true,
                });
              },
            }
          );
        } else {
          toast({
            title: 'Variant updated',
            status: 'success',
            isClosable: true,
          });
          reset(data);
        }
      },
      onError: () => {
        toast({
          title: 'Variant update failed',
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1}>
        {Object.keys(EditVariantFormSchema.shape).map((field) => {
          switch (field) {
            case 'ProductId':
            case 'VariantId':
            case 'SKU':
            case 'Image':
            case 'Price':
            case 'Available':
              return (
                <FormControl key={field} isInvalid={!!errors[field]}>
                  <FormLabel htmlFor={field}>{field}</FormLabel>
                  <Input
                    id={field}
                    {...register(field, {
                      ...(field === 'Available' && { valueAsNumber: true }),
                    })}
                    {...(field === 'Available' && { type: 'number' })}
                    {...(['ProductId', 'VariantId'].includes(field) && {
                      isDisabled: true,
                    })}
                  />
                  <FormErrorMessage>{errors[field]?.message}</FormErrorMessage>
                </FormControl>
              );
            case 'Combination':
              return (
                <FormControl key={field} isInvalid={!!errors[field]}>
                  <FormLabel htmlFor={field}>{field}</FormLabel>
                  <Stack>
                    <List spacing={2}>
                      {fields.map((item, index) => {
                        return (
                          <ListItem key={item.id}>
                            <HStack spacing={2}>
                              <Input
                                {...register(`${field}.${index}.${0}`, {
                                  required: true,
                                })}
                              />
                              <Input
                                {...register(`${field}.${index}.${1}`, {
                                  required: true,
                                })}
                              />
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => remove(index)}
                              >
                                X
                              </Button>
                            </HStack>
                          </ListItem>
                        );
                      })}
                    </List>
                    <Button onClick={() => append([[]])} width="100%" colorScheme="blue">
                      +
                    </Button>
                  </Stack>
                  <FormErrorMessage>
                    {errors[field]?.message as string | undefined}
                  </FormErrorMessage>
                </FormControl>
              );
            case 'Description':
              return (
                <FormControl key={field} isInvalid={!!errors[field]}>
                  <FormLabel htmlFor={field}>{field}</FormLabel>
                  <Textarea id={field} {...register(field)} />
                  <FormErrorMessage>
                    {errors[field]?.message as string | undefined}
                  </FormErrorMessage>
                </FormControl>
              );
            default:
              return null;
          }
        })}
      </Stack>

      <ButtonGroup marginTop={4}>
        <Button type="submit" colorScheme="blue" isDisabled={!isDirty || isLoading}>
          Save
        </Button>
      </ButtonGroup>
    </form>
  );
};
